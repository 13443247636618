package admin.ui.merchantDemoManager

import admin.model.DataRepository
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.stateIn
import model.PopulatedCoalition

class NameIdLookupViewModel(
    private val dataRepository: DataRepository,
    private val scope: CoroutineScope,
) {
    private val searchTextMutableStateFlow = MutableStateFlow<String?>(null)
    val searchTextStateFlow = searchTextMutableStateFlow.asStateFlow()

    val coalitionsStateFlow = dataRepository
        .populatedCoalitionsStateFlow
        .combine(searchTextMutableStateFlow) {
            trackedCoalitions, searchText -> filterListItems(searchText, trackedCoalitions.populatedCoalitions)
        }
        .stateIn(scope, SharingStarted.WhileSubscribed(), listOf())

    private fun filterListItems(searchText: String?, coalitions: List<PopulatedCoalition>): List<PopulatedCoalition> {
        return if (searchText != null) {
            coalitions.mapNotNull coalitionFilter@ { coalition ->
                if (coalition.name.contains(searchText, ignoreCase = true)) {
                    return@coalitionFilter coalition
                } else {
                    val filteredMerchants = coalition.merchants.mapNotNull merchantFilter@ { merchant ->
                        if (merchant.name.contains(searchText, ignoreCase = true)) {
                            return@merchantFilter merchant
                        } else {
                            val filteredLocations = merchant.locations.filter {
                                it.name.orEmpty().contains(searchText, ignoreCase = true)
                            }.toMutableList()
                            if (filteredLocations.isNotEmpty()) {
                                return@merchantFilter merchant.copy(locations = filteredLocations)
                            }
                            return@merchantFilter null
                        }
                    }.toMutableList()

                    if (filteredMerchants.isNotEmpty()) {
                        return@coalitionFilter coalition.copy(merchants = filteredMerchants)
                    }
                    return@coalitionFilter null
                }
            }
        } else {
            coalitions
        }
    }

    fun onSearchTextChange(s: String) {
        searchTextMutableStateFlow.value = s
    }
}