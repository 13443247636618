package admin.ui

import Flavor
import admin.ConfigFile
import admin.model.DataRepository
import admin.navigation.NavigationState
import admin.ui.snackbars.SnackBarState
import androidx.lifecycle.ViewModel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow

class AppViewModel(
    private val flavor: Flavor,
    val clientConfig: ConfigFile,
    private val dataRepository: DataRepository,
) : ViewModel() {

    val clientName = flavor.clientName

    private val dialogStateMutableStateFlow = MutableStateFlow<DialogState>(DialogState.Nothing())
    val dialogStateStateFlow = dialogStateMutableStateFlow

    val navigationStateFlow: StateFlow<NavigationState> = dataRepository.navigationStateFlow
    val backStackStateFlow: StateFlow<List<NavigationState>> = dataRepository.backStackStateFlow
    val snackbarStateStateFlow: StateFlow<SnackBarState> = dataRepository.snackbarStateStateFlow

    sealed class DialogState() {
        class Nothing() : DialogState()
        class LogOutConfirmation(val onConfirm: (Boolean) -> Unit) : DialogState()
    }

    fun hashStateChangeEvent(newHash: String, locationListener: (String) -> Unit) {
        dataRepository.hashStateChangeEvent(newHash, locationListener)
    }

    fun popBackStack() {
        dataRepository.popBackStack()
    }

    fun getToken(): String? {
        return dataRepository.getToken()
    }

    fun isTokenAvailable(): Boolean {
        return !dataRepository.getToken().isNullOrEmpty()
    }

    fun requestLogOut() {
        dialogStateMutableStateFlow.value = DialogState.LogOutConfirmation {
            dialogStateMutableStateFlow.value = DialogState.Nothing()
            if (it) {
                dataRepository.logOut()
            }
        }
    }
}