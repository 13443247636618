@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package linx.adminportal.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val empty_string: StringResource by 
      lazy { init_empty_string() }

  public val error_retrieving_ereceipt: StringResource by 
      lazy { init_error_retrieving_ereceipt() }

  public val merchants_near_me: StringResource by 
      lazy { init_merchants_near_me() }

  public val referMessage1: StringResource by 
      lazy { init_referMessage1() }

  public val referMessage2: StringResource by 
      lazy { init_referMessage2() }

  public val thank_you_we_will_message: StringResource by 
      lazy { init_thank_you_we_will_message() }

  public val valued_customer: StringResource by 
      lazy { init_valued_customer() }
}

internal val Res.string.empty_string: StringResource
  get() = CommonMainString0.empty_string

private fun init_empty_string(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:empty_string", "empty_string",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/linx.adminportal.generated.resources/values/strings.commonMain.cvr", 10, 20),
    )
)

internal val Res.string.error_retrieving_ereceipt: StringResource
  get() = CommonMainString0.error_retrieving_ereceipt

private fun init_error_retrieving_ereceipt(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:error_retrieving_ereceipt", "error_retrieving_ereceipt",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/linx.adminportal.generated.resources/values/strings.commonMain.cvr", 31, 69),
    )
)

internal val Res.string.merchants_near_me: StringResource
  get() = CommonMainString0.merchants_near_me

private fun init_merchants_near_me(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:merchants_near_me", "merchants_near_me",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/linx.adminportal.generated.resources/values/strings.commonMain.cvr", 101, 49),
    )
)

internal val Res.string.referMessage1: StringResource
  get() = CommonMainString0.referMessage1

private fun init_referMessage1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:referMessage1", "referMessage1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/linx.adminportal.generated.resources/values/strings.commonMain.cvr", 151, 77),
    )
)

internal val Res.string.referMessage2: StringResource
  get() = CommonMainString0.referMessage2

private fun init_referMessage2(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:referMessage2", "referMessage2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/linx.adminportal.generated.resources/values/strings.commonMain.cvr", 229, 69),
    )
)

internal val Res.string.thank_you_we_will_message: StringResource
  get() = CommonMainString0.thank_you_we_will_message

private fun init_thank_you_we_will_message(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:thank_you_we_will_message", "thank_you_we_will_message",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/linx.adminportal.generated.resources/values/strings.commonMain.cvr", 299,
    117),
    )
)

internal val Res.string.valued_customer: StringResource
  get() = CommonMainString0.valued_customer

private fun init_valued_customer(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:valued_customer", "valued_customer",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/linx.adminportal.generated.resources/values/strings.commonMain.cvr", 417, 43),
    )
)
