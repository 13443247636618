package admin.ui.terminalInfo

import admin.ui.CoalitionListItemView
import admin.ui.Colors
import admin.ui.DeviceListItemView
import admin.ui.LocationListItemView
import admin.ui.MerchantListItemView
import admin.ui.dialogs.GenericConfirmationDialog
import admin.ui.scrollbar.LazyColumnScrollbar
import admin.ui.scrollbar.ListIndicatorSettings
import admin.ui.search.SearchTextField
import androidx.compose.animation.core.Animatable
import androidx.compose.animation.core.LinearEasing
import androidx.compose.animation.core.RepeatMode
import androidx.compose.animation.core.infiniteRepeatable
import androidx.compose.animation.core.tween
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.hoverable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.interaction.collectIsHoveredAsState
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.itemsIndexed
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.material.Button
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Refresh
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.unit.dp
import model.CoalitionMerchant
import model.ListItem
import model.LocationDevice
import model.MerchantLocation
import model.PopulatedCoalition
import org.koin.compose.koinInject
import receipt.DeviceStatuses
import receipt.hasFlag

@Composable
fun TerminalInfo(
    terminalInfoViewModel: TerminalInfoViewModel = koinInject(),
    toolbarComposable: (@Composable (Modifier) -> Unit) -> Unit,
) {
    val searchText by terminalInfoViewModel.searchTextStateFlow.collectAsState(null)
    toolbarComposable { modifier ->
        Row(verticalAlignment = Alignment.CenterVertically) {
            Box(modifier = Modifier.weight(1f)) {
                SearchTextField(searchText, onSearchTextChange = terminalInfoViewModel::onSearchTextChange, modifier)
            }
            Box(modifier = Modifier.weight(1f)) {
                if (terminalInfoViewModel.isFullAdmin()) {
                    Button(terminalInfoViewModel::onRefreshClicked) {
                        Row(verticalAlignment = Alignment.CenterVertically) {
                            Text("Refresh Active Devices")

                            val isRefreshing by terminalInfoViewModel.isPopulatedCoalitionsRefreshingStateFlow.collectAsState()
                            var currentRotation by remember { mutableStateOf(0f) }
                            val rotation = remember { Animatable(currentRotation) }
                            LaunchedEffect(isRefreshing) {
                                if (isRefreshing) {
                                    rotation.animateTo(
                                        targetValue = currentRotation + 360f,
                                        animationSpec = infiniteRepeatable(
                                            animation = tween(1000, easing = LinearEasing),
                                            repeatMode = RepeatMode.Restart
                                        )
                                    ) {
                                        currentRotation = value
                                    }
                                } else {
                                    rotation.animateTo(
                                        targetValue = 0f,
                                        animationSpec = tween(
                                            durationMillis = 100,
                                        )
                                    ) {
                                        currentRotation = value
                                    }
                                }

                            }
                            Icon(Icons.Default.Refresh, null, modifier.rotate(rotation.value))
                        }
                    }
                }
            }
        }
    }
    Row(modifier = Modifier.fillMaxWidth()) {
        val allCoalitions by terminalInfoViewModel.searchFilteredPopulatedCoalitionsStateFlow.collectAsState()
        CoalitionMerchantLocationTerminalList(
            modifier = Modifier.weight(1f),
            label = "All Terminals",
            listItems = allCoalitions.populatedCoalitions.flatMap { listOf(it) + it.merchants.flatMap { listOf(it) + it.locations.flatMap { listOf(it) + it.devices } } },
            onTerminalClicked = terminalInfoViewModel::terminalClicked,
        )
        val activeTerminals by terminalInfoViewModel.activeTerminalCoalitionsStateFlow.collectAsState()
        CoalitionMerchantLocationTerminalList(
            modifier = Modifier.weight(1f),
            label = "Active Terminals",
            listItems = activeTerminals.populatedCoalitions.flatMap { listOf(it) + it.merchants.flatMap { listOf(it) + it.locations.flatMap { listOf(it) + it.devices } } },
            onTerminalClicked = terminalInfoViewModel::terminalClicked,
        )
    }
    HandleTerminalDialogs(terminalInfoViewModel)
}

@Composable
fun CoalitionMerchantLocationTerminalList(
    modifier: Modifier,
    label: String,
    listItems: List<ListItem>,
    onTerminalClicked: (LocationDevice) -> Unit,
) {
    Column(modifier = modifier) {
        Text(label)
        val lazyListState = rememberLazyListState()
        LazyColumnScrollbar(
            listState = lazyListState,
            padding = 4.dp,
            alwaysShowScrollBar = true,
            showItemIndicator = ListIndicatorSettings.Disabled,
        ) {
            LazyColumn(
                state = lazyListState,
            ) {
                itemsIndexed(listItems) { i, it->
                    val interactionSource = remember { MutableInteractionSource() }
                    val isHovered by interactionSource.collectIsHoveredAsState()
                    when (it) {
                        is PopulatedCoalition -> CoalitionListItemView(it, drawSeparator = i > 0)
                        is CoalitionMerchant -> MerchantListItemView(it)
                        is MerchantLocation -> LocationListItemView(it)
                        is LocationDevice -> DeviceListItemView(it, showId = true, modifier = Modifier
                            .hoverable(interactionSource)
                            .clickable { onTerminalClicked(it) }
                            .then(if (isHovered) Modifier.background(Colors.SelectBlue) else Modifier)
                        )
                        else -> {}
                    }
                }
            }
        }
    }
}

@Composable
fun HandleTerminalDialogs(terminalInfoViewModel: TerminalInfoViewModel) {
    val _dialogState by terminalInfoViewModel.dialogStateStateFlow.collectAsState()
    val dialogState = _dialogState

    when (dialogState) {
        is TerminalInfoViewModel.DialogState.None -> Unit
        is TerminalInfoViewModel.DialogState.Error -> Unit
        is TerminalInfoViewModel.DialogState.HideConfirmation -> {
            GenericConfirmationDialog(
                title = "Are you sure you want to ${ if (dialogState.device.statusType.hasFlag(DeviceStatuses.Hidden)) "un" else "" }hide this terminal?",
                onConfirm = "Yes" to { terminalInfoViewModel.setTerminalHidden(dialogState.device) },
                onDismiss = "Cancel" to terminalInfoViewModel::cancelDialog,
            )
        }
    }
}