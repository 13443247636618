package com.enrollandpay

import Flavor
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.russhwolf.settings.Settings
import com.russhwolf.settings.set
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.client.plugins.defaultRequest
import io.ktor.client.plugins.logging.Logger
import io.ktor.client.plugins.logging.Logging
import io.ktor.client.plugins.logging.SIMPLE
import io.ktor.client.request.get
import io.ktor.http.ContentType
import io.ktor.http.URLProtocol
import io.ktor.http.contentType
import io.ktor.http.path
import io.ktor.serialization.kotlinx.json.json
import isANumber
import kotlinx.coroutines.CoroutineExceptionHandler
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import model.SmsFullInformation

class MessagingViewModel(private val flavor: Flavor): ViewModel() {
    val settings = Settings()

    val httpClient = HttpClient {
        install(ContentNegotiation) { json(Json { ignoreUnknownKeys = true }) }

        install(Logging) { logger = Logger.SIMPLE }

        defaultRequest {
            url {
                host = flavor.serverUrl
                protocol = URLProtocol.HTTPS.takeIf { flavor.serverHttps } ?: URLProtocol.HTTP
                flavor.serverPort?.let {
                    port = it
                }
            }
        }
    }

    enum class NavigationState {
        PhoneEntry,
        Messages,
    }

    fun isSignedIn()= settings.getLongOrNull("user_id") != null

    private val navigationStateMutableStateFlow = MutableStateFlow(if (isSignedIn()) NavigationState.Messages else NavigationState.PhoneEntry)
    val navigationStateStateFlow = navigationStateMutableStateFlow.asStateFlow()

    private val phoneNumberMutableStateFlow = MutableStateFlow("")
    val phoneNumberState = phoneNumberMutableStateFlow.asStateFlow()

    private val isButtonEnableMutableStateFlow = MutableStateFlow(false)
    val isButtonEnableStateFlow = isButtonEnableMutableStateFlow.asStateFlow()

    private val countryCodeMutableStateFlow = MutableStateFlow(1)
    val countryCodeStateFlow = countryCodeMutableStateFlow.asStateFlow()

    private val messagesMutableStateFlow = MutableStateFlow<List<SmsFullInformation>?>(null)
    val messagesStateFlow = messagesMutableStateFlow.asStateFlow()

    fun resetUser() {
        settings.remove("user_id")
        phoneNumberMutableStateFlow.value = ""
        navigationStateMutableStateFlow.value = NavigationState.PhoneEntry
    }

    fun countryCodeSelected(countryCode: Int) {
        countryCodeMutableStateFlow.value = countryCode
    }

    fun updatePhoneNumber(phoneNumber: String) {
        var phoneNumber = phoneNumber
        if (phoneNumber.isANumber()) {
            if (phoneNumber.length > 10) {
                phoneNumber = phoneNumber.substring(0, 10)
            }
            phoneNumberMutableStateFlow.value = phoneNumber
            isButtonEnableMutableStateFlow.value = phoneNumber.length >= 10
        }
    }

    fun submitPhoneNumber() {
        if (isButtonEnableStateFlow.value) {
            val phoneNumberWithCountryCode = "${countryCodeStateFlow.value}${phoneNumberState.value}"
            val validatedPhoneNumber = phoneNumberWithCountryCode.toLongOrNull()
            if (validatedPhoneNumber != null) {
                navigationStateMutableStateFlow.value = NavigationState.Messages
                settings.set("user_id", validatedPhoneNumber)
            }
        }
    }

    fun lookupMessages(manualRefresh: Boolean = true) {
        if (manualRefresh) {
            messagesMutableStateFlow.value = null
        }
        val phoneNumber = settings.getLongOrNull("user_id")
        viewModelScope.launch(globalCoroutineExceptionHandler + Dispatchers.Default) {
            val response = httpClient.get {
                contentType(ContentType.Application.Json)
                url {
                    path("sms-info")
                    parameters.append("recipientNumber", Json.encodeToString(phoneNumber))
                }
            }
            messagesMutableStateFlow.value = response.body<List<SmsFullInformation>>()
        }
    }

    val globalCoroutineExceptionHandler = CoroutineExceptionHandler { _, exception ->
        println("Exception caught: $exception")
    }
}