package model

import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class DeleteCard(
    val consumerPhoneNumber: String,
    val amountTotal: Double?,
    val updateDateTime: LocalDateTime?,
    val locationUtcOffsetHour : Double,
    val card: Card,
)
