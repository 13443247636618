package admin.ui.nameIdLookup

import admin.ui.CoalitionListItemView
import admin.ui.LocationListItemView
import admin.ui.MerchantListItemView
import admin.ui.merchantDemoManager.NameIdLookupViewModel
import admin.ui.scrollbar.LazyColumnScrollbar
import admin.ui.scrollbar.ListIndicatorSettings
import admin.ui.search.SearchTextField
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.itemsIndexed
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import model.CoalitionMerchant
import model.ListItem
import model.MerchantLocation
import model.PopulatedCoalition
import org.koin.compose.koinInject

@Composable
fun NameIdLookup(
    nameIdLookupViewModelViewModel: NameIdLookupViewModel = koinInject(),
    toolbarComposable: (@Composable (Modifier) -> Unit) -> Unit,
) {
    val coalitions by nameIdLookupViewModelViewModel.coalitionsStateFlow.collectAsState()
    val searchText by nameIdLookupViewModelViewModel.searchTextStateFlow.collectAsState(null)
    toolbarComposable { modifier ->
        SearchTextField(searchText, onSearchTextChange = nameIdLookupViewModelViewModel::onSearchTextChange, modifier)
    }
    CoalitionMerchantLocationIdList(
        modifier = Modifier.fillMaxWidth(),
        listItems = coalitions.flatMap { listOf(it) + it.merchants.flatMap { listOf(it) + it.locations } },
    )
}

@Composable
fun CoalitionMerchantLocationIdList(
    modifier: Modifier,
    listItems: List<ListItem>,
) {
    Column(modifier = modifier) {
        val lazyListState = rememberLazyListState()
        LazyColumnScrollbar(
            listState = lazyListState,
            padding = 4.dp,
            alwaysShowScrollBar = true,
            showItemIndicator = ListIndicatorSettings.Disabled,
        ) {
            LazyColumn(
                state = lazyListState,
            ) {
                itemsIndexed(listItems) { i, it->
                    when (it) {
                        is PopulatedCoalition -> CoalitionListItemView(it, showId = true, drawSeparator = i > 0)
                        is CoalitionMerchant -> MerchantListItemView(it, showId = true)
                        is MerchantLocation -> LocationListItemView(it, showId = true)
                        else -> {}
                    }
                }
            }
        }
    }
}
