package admin.ui.merchantDemoManager

import admin.ui.CoalitionListItemView
import admin.ui.Colors
import admin.ui.LocationListItemView
import admin.ui.MerchantListItemView
import admin.ui.scrollbar.LazyColumnScrollbar
import admin.ui.scrollbar.ListIndicatorSettings
import admin.ui.search.SearchTextField
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.hoverable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.interaction.collectIsHoveredAsState
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.itemsIndexed
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import model.CoalitionMerchant
import model.ListItem
import model.MerchantLocation
import model.PopulatedCoalition
import org.koin.compose.koinInject

@Composable
fun MerchantDemoManager(
    merchantDemoManagerViewModel: MerchantDemoManagerViewModel = koinInject(),
    toolbarComposable: (@Composable (Modifier) -> Unit) -> Unit,
) {
    val coalitions by merchantDemoManagerViewModel.coalitionsStateFlow.collectAsState()
    val searchText by merchantDemoManagerViewModel.searchTextStateFlow.collectAsState(null)
    toolbarComposable { modifier ->
        SearchTextField(searchText, onSearchTextChange = merchantDemoManagerViewModel::onSearchTextChange, modifier)
    }
    Row(modifier = Modifier.fillMaxWidth()) {
        LabledCoalitionMerchantLocationList(
            modifier = Modifier.weight(1f),
            label = "Regular",
            listItems = coalitions.populatedCoalitions.filter { it.merchants.firstOrNull { !it.demo } != null }.flatMap { listOf(it) + it.merchants.filter { !it.demo }.flatMap { listOf(it) + it.locations } },
            onMerchantHoverColor = Colors.AddGreen,
            onMerchantClicked = { merchantDemoManagerViewModel.setMerchantDemo(it, true) },
        )
        LabledCoalitionMerchantLocationList(
            modifier = Modifier.weight(1f),
            label = "Demo",
            listItems = coalitions.populatedCoalitions.filter { it.merchants.firstOrNull { it.demo } != null }.flatMap { listOf(it) + it.merchants.filter { it.demo }.flatMap { listOf(it) + it.locations } },
            onMerchantHoverColor = Colors.RemoveRed,
            onMerchantClicked = { merchantDemoManagerViewModel.setMerchantDemo(it, false) }
        )
    }
}

@Composable
fun LabledCoalitionMerchantLocationList(
    modifier: Modifier,
    label: String,
    listItems: List<ListItem>,
    onMerchantHoverColor: Color,
    onMerchantClicked: (Int) -> Unit,
) {
    Column(modifier = modifier) {
        Text(label)
        val lazyListState = rememberLazyListState()
        LazyColumnScrollbar(
            listState = lazyListState,
            padding = 4.dp,
            alwaysShowScrollBar = true,
            showItemIndicator = ListIndicatorSettings.Disabled,
        ) {
            LazyColumn(
                state = lazyListState,
            ) {
                itemsIndexed(listItems) { i, it->
                    val interactionSource = remember { MutableInteractionSource() }
                    val isHovered by interactionSource.collectIsHoveredAsState()
                    when (it) {
                        is PopulatedCoalition -> CoalitionListItemView(it, drawSeparator = i > 0)
                        is CoalitionMerchant -> MerchantListItemView(it, modifier = Modifier
                            .hoverable(interactionSource)
                            .clickable { onMerchantClicked(it.id) }
                            .then(if (isHovered) Modifier.background(onMerchantHoverColor) else Modifier)
                        )
                        is MerchantLocation -> LocationListItemView(it)
                        else -> {}
                    }
                }
            }
        }
    }
}
