package admin.navigation

import admin.model.DataRepository
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

sealed class NavigationState(
    val title: String,
    val poppable: Boolean = true,
    val guid: String,
) {
    class Blank : NavigationState("", false, "")
    class Login : NavigationState("Login", false, "")
    class AdminHome : NavigationState("AdminHome", false, "")
    class MerchantDemoManager : NavigationState("MerchantDemoManager", false, "")
    class LocationNearbyGroupManager : NavigationState("LocationNearbyGroupManager", false, "")
    class NameId : NavigationState("NameId", false, "")
    class LinxSmsIntercept : NavigationState("LinxSmsIntercept", false, "")
    class Exit : NavigationState("Exit", false, "")
    class CoalitionCreator : NavigationState("CoalitionCreator", false, "")
    class TerminalInfo : NavigationState("TerminalInfo", false, "")
    class MerchantActivity : NavigationState("MerchantActivity", false, "")

    companion object: KoinComponent {
        val dataRepository: DataRepository by inject()

        fun fromHash(hash: String): NavigationState? {
            val params: Map<String, String> = hash.split("&").filterNot { it.isEmpty() }.associate {
                it.split("=").run {
                    first() to last()
                }
            }.onEach { println("Param: ${it.key} = ${it.value}") }

            val location = params.keys.firstOrNull() ?: "Login"
            var guid: String? = null
            params[location]?.let {
                if (it.isNotBlank() && it != location) {
                    guid = it
                }
            }

            return when (location.lowercase()) {
                "Login".lowercase() -> Login()
                "AdminHome".lowercase() -> Login()
                "MerchantDemoManager".lowercase() -> MerchantDemoManager()
                "NameId".lowercase() -> NameId()
                "LinxSmsIntercept".lowercase() -> LinxSmsIntercept()
                "CoalitionCreator".lowercase() -> CoalitionCreator()
                "TerminalInfo".lowercase() -> TerminalInfo()
                "MerchantActivity".lowercase() -> MerchantActivity()
                "LocationNearbyGroupManager".lowercase() -> LocationNearbyGroupManager()
                else -> null.also { println("Invalid location in: '$hash'") }
            }
        }
    }

    fun asUrlString(): String {
        return when {
            this is Login -> ""
            this is Blank
                || this is Exit
                -> title
            else -> """${title}=${guid}"""
        }
            .also { println("asUrlString: $it") }
    }

    override fun toString(): String {
        return "NavigationState(title='$title', poppable=$poppable, guid='$guid')"
    }

    override fun equals(other: Any?): Boolean {
        if (other is NavigationState) {
            if (title == other.title && guid == other.guid) {
                return true
            }
        }
        return false
    }
}