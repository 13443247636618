package admin.ui.locationNearbyGroupManager

import admin.model.DataRepository
import admin.model.TrackedPopulatedCoalition
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.stateIn
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import model.AdminAccess
import model.MerchantLocation
import model.PopulatedCoalition
import model.PopulatedRegion

class LocationNearbyGroupManagerViewModel(
    private val dataRepository: DataRepository,
    private val scope: CoroutineScope,
) {
    sealed class DialogState {
        class None: DialogState()
        class Confirm(val regionId: Int, val locationId: Int, val add: Boolean, val onConfirm: () -> Unit): DialogState()
        class NewRegion(val onConfirm: (String) -> Unit): DialogState()
        class Success: DialogState()
        class Error: DialogState()
    }

    class TrackedPopulatedRegions(val regions: List<PopulatedRegion> = emptyList()) {
        val updated = Clock.System.now()
    }

    private val dialogStateMutableStateFlow = MutableStateFlow<DialogState>(DialogState.None())
    val dialogStateStateFlow = dialogStateMutableStateFlow

    private val searchTextMutableStateFlow = MutableStateFlow<String?>(null)
    val searchTextStateFlow = searchTextMutableStateFlow.asStateFlow()

    private val selectedRegionIdMutableStateFlow = MutableStateFlow<Int?>(null)
    val selectedRegionIdStateFlow = selectedRegionIdMutableStateFlow.asStateFlow()

    private val populatedCoalitionsStateFlow = dataRepository.populatedCoalitionsStateFlow

    private val regionsMutableStateFlow = MutableStateFlow<TrackedPopulatedRegions>(TrackedPopulatedRegions())
    val regionsStateFlow = regionsMutableStateFlow
        .combine(populatedCoalitionsStateFlow) { trackedRegions, populatedCoalitions ->
            TrackedPopulatedRegions(trackedRegions.regions.map { region ->
                region.copy(locations = region.locations
                    .mapNotNull { location ->
                        populatedCoalitions.locationFromId(location.id)
                    }.toMutableList()
                )
            })
        }
        .stateIn(scope, SharingStarted.WhileSubscribed(), TrackedPopulatedRegions())


    val coalitionsStateFlow = dataRepository
        .populatedCoalitionsStateFlow
        .combine(searchTextMutableStateFlow) { trackedCoalitions, searchText ->
            TrackedPopulatedCoalition(filterListItems(searchText, trackedCoalitions.populatedCoalitions))
        }
        .stateIn(scope, SharingStarted.WhileSubscribed(), TrackedPopulatedCoalition())

    val isAdmin: Boolean by lazy {
        dataRepository.accessLevel == AdminAccess.FullAdmin
    }

    private fun filterListItems(searchText: String?, coalitions: List<PopulatedCoalition>): List<PopulatedCoalition> {
        return if (searchText != null) {
            coalitions.mapNotNull coalitionFilter@ { coalition ->
                if (coalition.name.contains(searchText, ignoreCase = true)) {
                    return@coalitionFilter coalition
                } else {
                    val filteredMerchants = coalition.merchants.mapNotNull merchantFilter@ { merchant ->
                        if (merchant.name.contains(searchText, ignoreCase = true)) {
                            return@merchantFilter merchant
                        } else {
                            val filteredLocations = merchant.locations.filter {
                                it.name.orEmpty().contains(searchText, ignoreCase = true)
                            }.toMutableList()
                            if (filteredLocations.isNotEmpty()) {
                                return@merchantFilter merchant.copy(locations = filteredLocations)
                            }
                            return@merchantFilter null
                        }
                    }.toMutableList()

                    if (filteredMerchants.isNotEmpty()) {
                        return@coalitionFilter coalition.copy(merchants = filteredMerchants)
                    }
                    return@coalitionFilter null
                }
            }
        } else {
            coalitions
        }
    }

    fun onSearchTextChange(s: String) {
        searchTextMutableStateFlow.value = s
    }

    fun onRegionClicked(regionId: Int) {
        if (regionId == -1) {
            dialogStateMutableStateFlow.value = DialogState.NewRegion { newRegion(it) }
        } else {
            selectedRegionIdMutableStateFlow.update {
                if (it == regionId) {
                    null
                } else {
                    regionId
                }
            }
        }
    }

    fun confirmAddLocation(locationId: Int) {
        selectedRegionIdStateFlow.value?.let {
            dialogStateMutableStateFlow.value = DialogState.Confirm(it, locationId, true, { addLocation(it, locationId) })
        }
    }

    fun confirmRemoveLocation(locationId: Int) {
        val regionId = regionFromLocationId(locationId)?.id ?: return
        dialogStateMutableStateFlow.value = DialogState.Confirm(regionId, locationId, false, { removeLocation(regionId, locationId) })
    }

    private fun addLocation(regionId: Int, locationId: Int) {
        scope.launch {
            val result = dataRepository.addLocation(regionId, locationId)
            if (result) {
                dialogStateMutableStateFlow.value = DialogState.Success()
                populatedCoalitionsStateFlow.value.locationFromId(locationId)?.let {
                    regionsMutableStateFlow.update { trackedRegions ->
                        trackedRegions.regions.firstOrNull { it.id == regionId }?.locations?.add(it)
                        TrackedPopulatedRegions(trackedRegions.regions)
                    }
                }
            } else {
                dialogStateMutableStateFlow.value = DialogState.Error()
            }
        }
    }

    private fun removeLocation(regionId: Int, locationId: Int) {
        scope.launch {
            val result = dataRepository.removeLocation(regionId, locationId)
            if (result) {
                dialogStateMutableStateFlow.value = DialogState.Success()
                regionsMutableStateFlow.update { trackedRegions ->
                    trackedRegions.regions.firstOrNull { it.id == regionId }?.locations?.removeAll { it.id == locationId }
                    TrackedPopulatedRegions(trackedRegions.regions)
                }
            } else {
                dialogStateMutableStateFlow.value = DialogState.Error()
            }
        }
    }

    private fun newRegion(regionName: String) {
        scope.launch {
            val newRegionId = dataRepository.newRegion(regionName)
            if (newRegionId != null) {
                dialogStateMutableStateFlow.value = DialogState.Success()
                regionsMutableStateFlow.update { trackedRegions ->
                    TrackedPopulatedRegions(trackedRegions.regions + PopulatedRegion(newRegionId, regionName, mutableListOf()))
                }
            } else {
                dialogStateMutableStateFlow.value = DialogState.Error()
            }
        }
    }

    fun cancelDialog() {
        dialogStateMutableStateFlow.value = DialogState.None()
    }

    fun regionFromLocationId(locationId: Int): PopulatedRegion? {
        return regionsStateFlow.value.regions.firstOrNull { it.locations.any { it.id == locationId } }
    }

    fun loationNameFromId(locationId: Int): String {
        return populatedCoalitionsStateFlow.value.locationFromId(locationId)?.name ?: "Unknown"
    }

    fun TrackedPopulatedCoalition.locationFromId(locationId: Int): MerchantLocation? {
        return populatedCoalitions.firstNotNullOfOrNull { it.merchants.firstNotNullOfOrNull { it.locations.firstOrNull { it.id == locationId } } }
    }

    fun regionNameFromId(regionId: Int): String {
        return regionsStateFlow.value.regions.firstOrNull { it.id == regionId }?.name ?: "Unknown"
    }

    init {
        scope.launch {
            regionsMutableStateFlow.value = TrackedPopulatedRegions(dataRepository.getRegions())
            regionsStateFlow.collect {
                if (it.regions.size == 1) {
                    selectedRegionIdMutableStateFlow.value = it.regions.first().id
                }
            }
        }
    }
}