package admin.ui.coalitionCreator

import admin.ui.dialogs.ErrorCoalitionCreatorDialog
import admin.ui.dialogs.SuccessCoalitionCreatorDialog
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.GridItemSpan
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.lazy.grid.itemsIndexed
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.foundation.text.selection.SelectionContainer
import androidx.compose.material.Button
import androidx.compose.material.Text
import androidx.compose.material3.OutlinedTextField
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.unit.dp
import org.koin.compose.koinInject
import kotlin.math.roundToInt

@Composable
fun CoalitionCreator(
    coalitionCreatorViewModel: CoalitionCreatorViewModel = koinInject(),
) {
    val coalitionLongName by coalitionCreatorViewModel.coalitionLongNameStateFlow.collectAsState()
    val coalitionShortName by coalitionCreatorViewModel.coalitionShortNameStateFlow.collectAsState()
    val isButtonEnable by coalitionCreatorViewModel.isButtonEnabledStateFlow.collectAsState()
    val coalitions by coalitionCreatorViewModel.coalitionsWithUsers.collectAsState()

    LazyVerticalGrid(
        columns = GridCells.Fixed(2),
    ) {
        if (coalitionCreatorViewModel.isFullAdmin()) {
            item(span = { GridItemSpan(this.maxLineSpan) }) {
                Column(modifier = Modifier.padding(16.dp), horizontalAlignment = Alignment.CenterHorizontally) {
                    Text("Coalition Creator")

                    OutlinedTextField(
                        value = coalitionLongName,
                        onValueChange = {
                            coalitionCreatorViewModel.onCoalitionLongNameChanged(it)
                        },
                        label = {
                            Text(text = "Coalition Long Name")
                        },
                        keyboardOptions = KeyboardOptions(keyboardType = KeyboardType.Text),
                        shape = RoundedCornerShape(0.dp),
                        singleLine = true,
                        modifier = Modifier,
                    )

                    OutlinedTextField(
                        value = coalitionShortName,
                        onValueChange = {
                            coalitionCreatorViewModel.onCoalitionShortNameChanged(it)
                        },
                        label = {
                            Text(text = "Coalition Short Name")
                        },
                        keyboardOptions = KeyboardOptions(keyboardType = KeyboardType.Text),
                        shape = RoundedCornerShape(0.dp),
                        singleLine = true,
                        modifier = Modifier,
                    )

                    Button(
                        onClick = { coalitionCreatorViewModel.submitCoalition() },
                        enabled = isButtonEnable,
                    ) {
                        Text("Submit", color = if (isButtonEnable) Color.White else Color.Black)
                    }

                    HandleCoalitionCreatorDialogs(coalitionCreatorViewModel)
                }
            }
        }
        item(span = { GridItemSpan(this.maxLineSpan) }) {
            Text("Coalitions", modifier = Modifier.padding(start = 16.dp))
        }
        itemsIndexed(coalitions.zip(coalitions).flatMap { listOf(it.first, it.second) }) { index, coalition ->
            val modifier = if (((index + 1) / 2.0).roundToInt() % 2 == 0) Modifier.background(Color.LightGray) else Modifier
            if (index % 2 == 0) {
                Text(coalition.coalition.name, modifier = modifier)
            } else {
                SelectionContainer {
                    Text(coalition.user?.userEmail.orEmpty(), modifier = modifier)
                }
            }
        }
    }
}

@Composable
fun HandleCoalitionCreatorDialogs(coalitionCreatorViewModel: CoalitionCreatorViewModel) {
    val _dialogState by coalitionCreatorViewModel.dialogStateStateFlow.collectAsState()
    val dialogState = _dialogState

    when (dialogState) {
        is CoalitionCreatorViewModel.DialogState.None -> Unit
        is CoalitionCreatorViewModel.DialogState.Error -> {
            ErrorCoalitionCreatorDialog(coalitionCreatorViewModel::dismissDialog)
        }

        is CoalitionCreatorViewModel.DialogState.Success -> {
            SuccessCoalitionCreatorDialog(coalitionCreatorViewModel::dismissDialog)
        }
    }
}
