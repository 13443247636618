package model

import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class DeviceTracking(
    val deviceTrackingId: Int,
    val deviceId: Int?,
    val applicationVersion: String?,
    val applicationPackageName: String?,
    val flavor: String?,
    val make: String?,
    val model: String?,
    val serialNumber: String?,
    val createdOn: LocalDateTime?,
)