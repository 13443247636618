package admin.persistence

import model.AdminAccess

class AccessPersistence(val persistence: Persistence) {
    fun getAccess(): AdminAccess? {
        return persistence.get("access")?.toIntOrNull()?.let { accessLevel ->
            return AdminAccess.entries.firstOrNull { accessLevel == it.accessLevel }
        }
    }

    fun setAccess(access: String) {
        persistence.set("access", access)
    }

    fun deleteAccess() {
        persistence.delete("access")
    }
}