package admin.ui

import androidx.compose.ui.graphics.Color

class Colors {
    companion object {
        val Primary = Color(0xFFeff3f9)
        val Secondary = Color(0xFF213246)
        val Highlight = Color(0xFF30a0e6)
        val Header = Color(0xFFdce4f0)
        val Default = Color(0xFFF0F2F5)
        val AddGreen = Color(0xFF00ff00)
        val RemoveRed = Color(0xFFff0000)
        val SelectBlue = Color(0xFF82b7ff)
        val PrivacyBlue = Color(0xFF50A9C9)
        val EreceiptGreen = Color(0xFFC0E0B8)
        val SimpleEreceiptBackground = Color(0xffdee2e6)
        val TransactionBorderStroke= Color(0xFFBDC5CD)
        val alertBanner = Color(0xFF0460D7)
        val MerchantsNearMeGreen = Color(0xFF0A9452)
        val Disabled = Color(0xFFCECECE)
        val DisabledProgressbar = Color(0xFF727272)
    }
}
