package admin.ui.dialogs

import admin.clearSemantics
import admin.ui.Colors
import androidx.compose.foundation.layout.widthIn
import androidx.compose.material.AlertDialog
import androidx.compose.material.Text
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp

@Composable
fun GenericConfirmationDialog(
    title: String,
    onConfirm: Pair<String, () -> Unit>,
    onDismiss: Pair<String?, () -> Unit>,
) {
    AlertDialog(
        modifier = Modifier.widthIn(280.dp, 320.dp),
        title = { Text(title, modifier = Modifier.clearSemantics()) },
        onDismissRequest = onDismiss.second,
        confirmButton = {
            Button(
                onClick = onConfirm.second,
                colors = ButtonDefaults.buttonColors(
                    containerColor = Colors.Secondary
                )
            ) {
                Text(onConfirm.first, color = Color.White)
            }
        },
        dismissButton = {
            onDismiss.first?.let {
                Button(
                    onClick = onDismiss.second,
                    colors = ButtonDefaults.buttonColors(
                        containerColor = Colors.Secondary
                    )
                ) {
                    Text(it, color = Color.White)
                }
            }
        }
    )
}