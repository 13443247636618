package admin.ui.locationNearbyGroupManager

import admin.ui.CoalitionListItemView
import admin.ui.Colors
import admin.ui.LocationListItemView
import admin.ui.MerchantListItemView
import admin.ui.dialogs.GenericConfirmationDialog
import admin.ui.scrollbar.LazyColumnScrollbar
import admin.ui.scrollbar.ListIndicatorSettings
import admin.ui.search.SearchTextField
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.hoverable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.interaction.collectIsHoveredAsState
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.itemsIndexed
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.material.Text
import androidx.compose.material.TextField
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import model.CoalitionMerchant
import model.ListItem
import model.MerchantLocation
import model.PopulatedCoalition
import model.PopulatedRegion
import org.koin.compose.koinInject

@Composable
fun LocationNearbyGroupManager(
    locationNearbyGroupManagerViewModel: LocationNearbyGroupManagerViewModel = koinInject(),
    toolbarComposable: (@Composable (Modifier) -> Unit) -> Unit,
) {
    val coalitions by locationNearbyGroupManagerViewModel.coalitionsStateFlow.collectAsState()
    val regions by locationNearbyGroupManagerViewModel.regionsStateFlow.collectAsState()
    val searchText by locationNearbyGroupManagerViewModel.searchTextStateFlow.collectAsState(null)
    toolbarComposable { modifier ->
        Row {
            Text(
                modifier = Modifier.fillMaxWidth(.5f),
                text = "Select a Region, then select a location to add or remove from the region. Seattle is selected by default."
            )
            SearchTextField(searchText, onSearchTextChange = locationNearbyGroupManagerViewModel::onSearchTextChange, modifier)
        }
    }
    Row(modifier = Modifier.fillMaxWidth()) {
        // Select a region, then select a location to be asked "do you want", add a region button
        val selectedRegionId by locationNearbyGroupManagerViewModel.selectedRegionIdStateFlow.collectAsState()
        RegionsAndLocationsList(
            modifier = Modifier.weight(1f),
            label = "Regions and Locations",
            regions = regions.regions,
            onMerchantHoverColor = Colors.RemoveRed,
            selectedRegionId = selectedRegionId,
            onRegionClicked = locationNearbyGroupManagerViewModel::onRegionClicked,
            onLocationClicked = { locationNearbyGroupManagerViewModel.confirmRemoveLocation(it) },
            isAdmin = locationNearbyGroupManagerViewModel.isAdmin,
        )
        CoalitionMerchantList(
            modifier = Modifier.weight(1f),
            label = "Locations with no region",
            listItems = coalitions.populatedCoalitions.flatMap { it.merchants.flatMap { listOf(it) + it.locations.filter { location -> regions.regions.firstOrNull { it.locations.contains(location) } == null } } },
            onMerchantHoverColor = Colors.AddGreen,
            onMerchantClicked = { locationNearbyGroupManagerViewModel.confirmAddLocation(it) },
        )
    }
    HandleDialogs(locationNearbyGroupManagerViewModel)
}

@Composable
fun RegionsAndLocationsList(
    modifier: Modifier,
    label: String,
    regions: List<PopulatedRegion>,
    onMerchantHoverColor: Color,
    selectedRegionId: Int?,
    onRegionClicked: (Int) -> Unit,
    onLocationClicked: (Int) -> Unit,
    isAdmin: Boolean,
) {
    Column(modifier = modifier) {
        Text(label)
        val lazyListState = rememberLazyListState()
        LazyColumnScrollbar(
            listState = lazyListState,
            padding = 4.dp,
            alwaysShowScrollBar = true,
            showItemIndicator = ListIndicatorSettings.Disabled,
        ) {
            LazyColumn(
                state = lazyListState,
            ) {
                itemsIndexed(regions) { i, it->
                    Column {
                        val interactionSource = remember { MutableInteractionSource() }
                        val isHovered by interactionSource.collectIsHoveredAsState()
                        val selected = selectedRegionId == it.id
                        Text(
                            modifier = Modifier
                                .then(if (isHovered || selected) Modifier.background(Colors.Highlight) else Modifier)
                                .hoverable(interactionSource)
                                .clickable { onRegionClicked(it.id) }
                            ,
                            text = it.name
                        )
                        it.locations.forEach {
                            val interactionSource = remember { MutableInteractionSource() }
                            val isHovered by interactionSource.collectIsHoveredAsState()
                            LocationListItemView(it,
                                modifier = Modifier
                                    .hoverable(interactionSource)
                                    .clickable { onLocationClicked(it.id) }
                                    .then(if (isHovered) Modifier.background(onMerchantHoverColor) else Modifier)
                            )
                        }
                    }
                }
                if (isAdmin) {
                    item {
                        Text("+ New Region", modifier = Modifier.clickable { onRegionClicked(-1) })
                    }
                }
            }
        }
    }
}


@Composable
fun CoalitionMerchantList(
    modifier: Modifier,
    label: String,
    listItems: List<ListItem>,
    onMerchantHoverColor: Color,
    onMerchantClicked: (Int) -> Unit,
) {
    Column(modifier = modifier) {
        Text(label)
        val lazyListState = rememberLazyListState()
        LazyColumnScrollbar(
            listState = lazyListState,
            padding = 4.dp,
            alwaysShowScrollBar = true,
            showItemIndicator = ListIndicatorSettings.Disabled,
        ) {
            LazyColumn(
                state = lazyListState,
            ) {
                itemsIndexed(listItems) { i, it->
                    val interactionSource = remember { MutableInteractionSource() }
                    val isHovered by interactionSource.collectIsHoveredAsState()
                    when (it) {
                        is PopulatedCoalition -> CoalitionListItemView(it, drawSeparator = i > 0)
                        is CoalitionMerchant -> MerchantListItemView(it)
                        is MerchantLocation -> LocationListItemView(it, modifier = Modifier
                            .hoverable(interactionSource)
                            .clickable { onMerchantClicked(it.id) }
                            .then(if (isHovered) Modifier.background(onMerchantHoverColor) else Modifier)
                        )
                        else -> {}
                    }
                }
            }
        }
    }
}

@Composable
fun HandleDialogs(locationNearbyGroupManagerViewModel: LocationNearbyGroupManagerViewModel) {
    val _dialogState by locationNearbyGroupManagerViewModel.dialogStateStateFlow.collectAsState()
    val dialogState = _dialogState

    when (dialogState) {
        is LocationNearbyGroupManagerViewModel.DialogState.None -> Unit
        is LocationNearbyGroupManagerViewModel.DialogState.Error -> Unit
        is LocationNearbyGroupManagerViewModel.DialogState.Success -> Unit
        is LocationNearbyGroupManagerViewModel.DialogState.Confirm -> {
            GenericConfirmationDialog(
                title = "Would you like to ${if (dialogState.add) "add" else "remove" } ${ locationNearbyGroupManagerViewModel.loationNameFromId(dialogState.locationId) } ${if (dialogState.add) "to" else "from" } ${ locationNearbyGroupManagerViewModel.regionNameFromId(dialogState.regionId) }",
                onConfirm = "Yes" to { dialogState.onConfirm() },
                onDismiss = "Cancel" to locationNearbyGroupManagerViewModel::cancelDialog,
            )
        }
        is LocationNearbyGroupManagerViewModel.DialogState.NewRegion -> {
            var name by remember { mutableStateOf("") }
            AlertDialog(
                onDismissRequest = locationNearbyGroupManagerViewModel::cancelDialog,
                title = { Text("Region Name") },
                text = {
                    TextField(
                        modifier = Modifier.fillMaxWidth(),
                        value = name,
                        onValueChange = { name = it },
                    )
                },
                confirmButton = {
                    Button(
                        onClick = { dialogState.onConfirm(name) },
                        colors = ButtonDefaults.buttonColors(
                            containerColor = Colors.Secondary
                        )
                    ) {
                        Text("Save", color = Color.White)
                    }
                },
                dismissButton = {
                    Button(
                        onClick = locationNearbyGroupManagerViewModel::cancelDialog,
                        colors = ButtonDefaults.buttonColors(
                            containerColor = Colors.Secondary
                        )
                    ) {
                        Text("Cancel", color = Color.White)
                    }
                }
            )
        }
    }
}
