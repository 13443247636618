package admin.ui.home

import admin.navigation.NavigationState
import admin.ui.Colors
import admin.ui.coalitionCreator.CoalitionCreator
import admin.ui.locationNearbyGroupManager.LocationNearbyGroupManager
import admin.ui.merchantActivity.MerchantActivity
import admin.ui.merchantDemoManager.MerchantDemoManager
import admin.ui.nameIdLookup.NameIdLookup
import admin.ui.terminalInfo.TerminalInfo
import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.slideInHorizontally
import androidx.compose.animation.slideOutHorizontally
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.hoverable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.interaction.collectIsHoveredAsState
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Button
import androidx.compose.material.Icon
import androidx.compose.material.IconButton
import androidx.compose.material.Text
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Menu
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.dp
import com.enrollandpay.MessagingApp
import linx.adminportal.generated.resources.Res
import linx.adminportal.generated.resources.ep_logo
import org.jetbrains.compose.resources.painterResource
import org.koin.compose.koinInject

@Composable
fun AdminHome(
    adminHomeViewModel: AdminHomeViewModel = koinInject(),
) {
    var mobileView by remember { mutableStateOf(false) }
    var showMenu by remember { mutableStateOf(false) }
    val menuItems by adminHomeViewModel.menuItemsStateFlow.collectAsState()
    val localDensity = LocalDensity.current
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .onGloballyPositioned {
                with(localDensity) {
                    mobileView = it.size.width.toDp() < 600.dp
                    if (!mobileView) {
                        showMenu = false
                    }
                }
           }
        ,
    ) {
        if (!mobileView) {
            MenuSideBar(menuItems, adminHomeViewModel::logout) {
                showMenu = false
            }
        }
        Column(
            modifier = Modifier
                .fillMaxSize()
                .background(Colors.Primary)
            ,
        ) {
            var toolbarComposable: (@Composable (Modifier) -> Unit)? by remember { mutableStateOf(null) }
            Row(modifier = Modifier
                    .background(Colors.Header)
                    .height(92.dp)
                    .fillMaxWidth()
                    .padding(16.dp)
                ,
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                Box(modifier = Modifier.weight(1f)) {
                    toolbarComposable?.let {
                        it(Modifier)
                    }
                }
                if (mobileView) {
                    IconButton(onClick = { showMenu = !showMenu }) {
                        Icon(Icons.Default.Menu, null, tint = Colors.Secondary)
                    }
                }
            }
            Column(
                modifier = Modifier
                    .padding(16.dp)
            ) {

                val _navigationState by adminHomeViewModel.navigationStateFlow.collectAsState()
                val navigationState = _navigationState

                when (navigationState) {
                    is NavigationState.AdminHome -> {
                        val content = menuItems.firstOrNull { it.selected.value }?.text ?: "Content Goes Here"
                        Text(content)
                    }
                    is NavigationState.MerchantDemoManager -> MerchantDemoManager { toolbarComposable = it }
                    is NavigationState.NameId -> NameIdLookup { toolbarComposable = it }
                    is NavigationState.LinxSmsIntercept -> {
                        MessagingApp { toolbarComposable = it }
                    }
                    is NavigationState.CoalitionCreator -> {
                        CoalitionCreator()
                        toolbarComposable = null
                    }
                    is NavigationState.TerminalInfo -> {
                        TerminalInfo { toolbarComposable = it }
                    }
                    is NavigationState.MerchantActivity -> {
                        MerchantActivity { toolbarComposable = it }
                    }
                    is NavigationState.LocationNearbyGroupManager -> {
                        LocationNearbyGroupManager { toolbarComposable = it }
                    }
                    else -> Unit
                }
            }
        }

    }

    if (mobileView) {
        AnimatedVisibility(
            showMenu,
            enter = slideInHorizontally(),
            exit = slideOutHorizontally(

            ),
        ) {
            MenuSideBar(menuItems, adminHomeViewModel::logout) {
                showMenu = false
            }
        }
    }
}

@Composable
private fun MenuSideBar(menuItems: List<AdminHomeViewModel.MenuItem>, onLogout: () -> Unit, onClickCallback: () -> Unit = {}) {
    Column(
        modifier = Modifier
            .background(Colors.Secondary)
            .width(192.dp)
            .fillMaxHeight()
            .verticalScroll(rememberScrollState())
        ,
    ) {
        Box(
            modifier = Modifier
                .height(92.dp)
                .fillMaxWidth()
                .padding(16.dp),
            contentAlignment = Alignment.Center,
        ) {
            Image(
                painter = painterResource(Res.drawable.ep_logo),
                contentDescription = "Enroll and Pay"
            )
        }
        menuItems.forEach { MenuButton(it, onClickCallback, Modifier.fillMaxWidth()) }

        Spacer(Modifier.weight(1f))

        Box(Modifier.fillMaxWidth().padding(16.dp), contentAlignment = Alignment.BottomEnd) {
            Button(
                onClick = {
                    onLogout()
                    onClickCallback()
                },
            ) {
                Text("Log Out")
            }
        }
    }
}

@Composable
fun MenuButton(menuItem: AdminHomeViewModel.MenuItem, onClickCallback: () -> Unit = {}, modifier: Modifier = Modifier) {
    val interactionSource = remember { MutableInteractionSource() }
    val isHovered by interactionSource.collectIsHoveredAsState()
    val selected = menuItem.selected.value
    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = modifier
            .then(if (isHovered || selected) Modifier.background(Colors.Highlight) else Modifier)
            .hoverable(interactionSource)
            .clickable {
                menuItem.onClick(menuItem)
                onClickCallback()
            }
            .padding(vertical = 8.dp)
    ) {
        val padding = 40.dp
        menuItem.icon?.let {
            Image(modifier = Modifier.width(40.dp), painter = painterResource(it), contentDescription = null)
        }
        Text(
            modifier = Modifier.then(if (menuItem.icon == null) Modifier.padding(start = padding) else Modifier),
            text = menuItem.text,
            color = Color.White,
        )
    }
}