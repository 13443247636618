package com.enrollandpay

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.IntrinsicSize
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.material.Button
import androidx.compose.material.Icon
import androidx.compose.material.IconButton
import androidx.compose.material.MaterialTheme
import androidx.compose.material.Scaffold
import androidx.compose.material.Text
import androidx.compose.material.TopAppBar
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.shadow
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.unit.dp
import getFlavor
import ui.PhoneEntryField
import ui.PhonedVisualTransformation

@Composable
fun MessagingApp(externalTopAppBar: ((@Composable (Modifier) -> Unit) -> Unit)? = null) {
    val viewModel by remember { mutableStateOf(MessagingViewModel(getFlavor())) }
    MaterialTheme {
        Scaffold(
            topBar = {
                val phoneNumber by viewModel.phoneNumberState.collectAsState()
                val navigationState by viewModel.navigationStateStateFlow.collectAsState()
                val transformation by remember { mutableStateOf(PhonedVisualTransformation("(000) 000-0000", '0')) }
                if (externalTopAppBar == null) {
                    TopAppBar(
                        modifier = Modifier
                            .shadow(elevation = 0.dp),
                        title = {
                            phoneNumber.takeIf { navigationState == MessagingViewModel.NavigationState.Messages }?.let {
                                Text(transformation.filter(AnnotatedString(it)).text.text)
                            }
                        },
                        elevation = 0.dp,
                        navigationIcon = {
                            if (navigationState == MessagingViewModel.NavigationState.Messages) {
                                IconButton(
                                    onClick = viewModel::resetUser
                                ) {
                                    Icon(
                                        imageVector = Icons.AutoMirrored.Filled.ArrowBack,
                                        contentDescription = ""
                                    )
                                }
                            }
                        }
                    )
                } else {
                    externalTopAppBar.invoke {
                        if (navigationState == MessagingViewModel.NavigationState.Messages) {
                            Row(verticalAlignment = Alignment.CenterVertically) {
                                IconButton(
                                    onClick = viewModel::resetUser
                                ) {
                                    Icon(
                                        imageVector = Icons.AutoMirrored.Filled.ArrowBack,
                                        contentDescription = ""
                                    )
                                }

                                phoneNumber.takeIf { navigationState == MessagingViewModel.NavigationState.Messages }?.let {
                                    Text(transformation.filter(AnnotatedString(it)).text.text)
                                }
                            }
                        }
                    }
                }
            }
        ) { it
            val navigationState by viewModel.navigationStateStateFlow.collectAsState()
            when (navigationState) {
                MessagingViewModel.NavigationState.PhoneEntry -> {
                    Column(
                        modifier = Modifier.fillMaxWidth(),
                        horizontalAlignment =Alignment.CenterHorizontally,
                    ) {
                        Row(modifier = Modifier.height(IntrinsicSize.Min)) {
                            PhoneEntryField(
                                phoneStateFlow = viewModel.phoneNumberState,
                                onPhoneNumberChanged = viewModel::updatePhoneNumber,
                                onEnterPressed = viewModel::submitPhoneNumber
                            )
                        }
                        val isButtonEnable by viewModel.isButtonEnableStateFlow.collectAsState()
                        Button(
                            onClick = viewModel::submitPhoneNumber,
                            enabled = isButtonEnable
                        ) {
                            Text("Log In", color = if (isButtonEnable) Color.White else Color.Black)
                        }
                    }
                }

                MessagingViewModel.NavigationState.Messages -> MessagesScreen(viewModel)
            }
        }
    }
}