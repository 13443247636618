package admin.ui

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import model.LocationDevice
import receipt.DeviceStatuses
import receipt.hasFlag

@Composable
fun DeviceListItemView(device: LocationDevice, showId: Boolean = false, modifier: Modifier = Modifier) {
    val hidden = device.statusType.hasFlag(DeviceStatuses.Hidden)
    val text = device.name +
            (device.id.takeIf { showId }?.let { " - $it" } ?: "") +
            (" (hidden)".takeIf { hidden } ?: "")
    Column {
        var deviceTrackingVisible by remember { mutableStateOf(false) }
        Row(modifier = modifier.fillMaxWidth(), verticalAlignment = Alignment.CenterVertically) {
            Text(
                modifier = Modifier
                    .padding(start = 64.dp),
                text = text,
                color = if (hidden) {
                    Color.Gray
                } else {
                    Color.Unspecified
                }
            )
            device.deviceTracking?.let {
                Icon(
                    modifier = Modifier
                        .clickable { deviceTrackingVisible = !deviceTrackingVisible }
                        .rotate(if (deviceTrackingVisible) 0f else 180f)
                    ,
                    imageVector = Icons.Default.ArrowDropDown,
                    contentDescription = null
                )
            }
        }
        device.deviceTracking?.let {
            AnimatedVisibility(deviceTrackingVisible) {
                Column {
                    it.applicationVersion?.let { DeviceTrackingStat("applicationVersion", it) }
                    it.applicationPackageName?.let { DeviceTrackingStat("applicationPackageName",it) }
                    it.flavor?.let { DeviceTrackingStat("flavor", it) }
                    it.make?.let { DeviceTrackingStat("make", it) }
                    it.model?.let { DeviceTrackingStat("model", it) }
                    it.serialNumber?.let { DeviceTrackingStat("serialNumber", it) }
                    it.createdOn?.let { DeviceTrackingStat("createdOn", it.toString()) }
                }
            }
        }
    }
}

@Composable
fun DeviceTrackingStat(label: String, value: String, modifier: Modifier = Modifier) {
    Text(
        modifier = modifier.padding(start = 80.dp).height(24.dp),
        text = "$label: $value",
        fontSize = 12.sp,
    )
}