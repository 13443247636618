import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import model.MerchantFullInformationV3
import navigation.NavigationState
import ui.AppViewModel
import ui.Colors
import ui.authentication.AuthScreen
import ui.consumerInformation.ConsumerInformationScreen
import ui.consumerInformation.MerchantCard
import ui.demoMerchantAdmin.DemoMerchantAdminScreen
import ui.eReceipt.EReceiptScreen
import ui.eReceipt.PrivacyPolicy
import ui.eReceipt.SimplifiedEReceiptScreen
import ui.eReceipt.TermsOfServiceScreen
import ui.login.LoginRequest
import ui.merchantInformation.MerchantInformationScreen
import ui.search.SearchScreen

@Composable
fun AppContent(
    viewModel: AppViewModel,
    locationListener: (String) -> Unit,
) {
    val _navigationState by viewModel.navigationStateFlow.collectAsState()
    val navigationState = _navigationState
    println("NavigationState: $navigationState")
    locationListener(navigationState.asUrlString())

    when (navigationState) {
        is NavigationState.Blank -> {
            Box(Modifier.fillMaxSize().background(Colors.Default))
        }

        is NavigationState.Login -> {
            LoginRequest(onSubmitted = viewModel::setSubmitted)
        }

        is NavigationState.LoginSubmitted -> {
            SubmittedScreen()
        }

        is NavigationState.Auth -> {
            AuthScreen("Authenticating user")
        }

        is NavigationState.AuthFailed -> {
            AuthScreen("Expired or invalid authorization link. You will be redirected to try again.")
        }

        is NavigationState.ConsumerInformation -> {
            ConsumerInformationScreen(
                onMerchantClicked = viewModel::setMerchant,
                fetchConsumerInformation = viewModel.consumerInformationStateFlow,
                merchantCardView = { merchant, onMerchantClick ->
                    MerchantCard(merchant, onMerchantClick)
                },
                shouldShowScrollbar = viewModel.clientConfig.shouldShowScrollBar
            )
        }

        is NavigationState.MerchantInformation -> {
            MerchantInformationScreen(
                onTransactionClicked = viewModel::setEreceipt,
                merchantInformationStateFlow = viewModel.merchantInformationStateFlow,
                paymentStateStateFlow = viewModel.paymentsStateFlow,
                cardsStateFlow = viewModel.cardsStateFlow,
                shouldShowScrollbar = viewModel.clientConfig.shouldShowScrollBar,
                onPullToRefresh = viewModel::loadPaymentsAsync,
                onDemoAdminClicked = viewModel::setDemoAdmin,
            ) { merchantInformationStateFlow, onMerchantClick ->
                val merchant by merchantInformationStateFlow.collectAsState()
                MerchantCard((merchant ?: MerchantFullInformationV3()), onMerchantClick, shouldDeactivateClickEffects = true)
            }
        }

        is NavigationState.EReceipt -> {
            EReceiptScreen(
                eReceiptStateFlow = viewModel.eReceiptStateFlow,
                onCancelEnrollmentClicked = viewModel::cancelEnrollment,
                onTermsOfServiceClicked = viewModel::setTermsOfService,
                onPrivacyPolicyClicked = viewModel::setPrivacyPolicy,
                onManageAccountClicked = viewModel::requestAuthenticationFromOrderPaymentGuid,
                isTokenAvailable = viewModel.isTokenAvailable(),
                questionStateFlow = viewModel.orderQuestionStateFlow,
                onScore = viewModel::answerQuestion,
                merchantInformationStateFlow = viewModel.merchantInformationStateFlow,
                consumerInformationStateFlow = viewModel.consumerInformationStateFlow,
            )
        }

        is NavigationState.TermsOfService -> {
            TermsOfServiceScreen()
        }

        is NavigationState.PrivacyPolicy -> {
            PrivacyPolicy()
        }

        is NavigationState.Search -> {
            val merchants = viewModel.filteredMerchantList
            SearchScreen(
                merchantsStateFlow = merchants,
                shouldShowScrollbar = viewModel.clientConfig.shouldShowScrollBar,
                searchText = viewModel.searchText,
                onSearchTextChange = viewModel::onSearchTextChange,
            )
        }

        is NavigationState.Exit -> {
            Box(Modifier.fillMaxSize().background(Colors.Default))
        }

        is NavigationState.SEReceipt -> {
            SimplifiedEReceiptScreen(
                eReceiptStateFlow = viewModel.eReceiptStateFlow,
            )
        }

        is NavigationState.DemoMerchantAdmin -> {
            DemoMerchantAdminScreen(
                shouldShowScrollbar = viewModel.clientConfig.shouldShowScrollBar,
                cardStateFlow = viewModel.cardDeleteStateFlow,
                onPullToRefresh = viewModel::loadDeleteCardAsync,
                onCardClicked = viewModel::deleteCard
            )
        }
    }
}