package model

enum class AdminAccess(val accessLevel: Int, val databaseRepresentativeType: Int) {
    /**
     * Mapping AdminAccess to RepresentativeType
     *
     * Role Admin = FullAdmin
     * Role Support = BillingAdmin
     * Role Sales = ViewAdmin
     *
     */

    FullAdmin(3, 1), //for developers, full CRUD
    BillingAdmin(2, 2), //for view and some CRUD operations (marking invoices as paid)
    ViewAdmin(1, 4), //for view only
}