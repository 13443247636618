package admin

import Flavor
import admin.model.DataRepository
import admin.model.NetworkController
import admin.model.impl.DefaultDataRepository
import admin.model.impl.NetworkControllerKtor
import admin.model.network.FlavorInjectedHttpClient
import admin.navigation.Navigation
import admin.persistence.AccessPersistence
import admin.persistence.MultiPlatformSettingsPersistence
import admin.persistence.Persistence
import admin.persistence.TokenPersistence
import admin.ui.AppViewModel
import admin.ui.coalitionCreator.CoalitionCreatorViewModel
import admin.ui.home.AdminHomeViewModel
import admin.ui.locationNearbyGroupManager.LocationNearbyGroupManagerViewModel
import admin.ui.login.LoginViewModel
import admin.ui.merchantActivity.MerchantActivityViewModel
import admin.ui.merchantDemoManager.MerchantDemoManagerViewModel
import admin.ui.merchantDemoManager.NameIdLookupViewModel
import admin.ui.terminalInfo.TerminalInfoViewModel
import getFlavor
import io.ktor.client.HttpClient
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import org.koin.core.module.dsl.bind
import org.koin.core.module.dsl.factoryOf
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.module

val appModules = module {
    factory<CoroutineDispatcher> { Dispatchers.Default }
    single<CoroutineScope> { CoroutineScope(get<CoroutineDispatcher>() + SupervisorJob() + globalCoroutineExceptionHandler) }

    single<HttpClient> { FlavorInjectedHttpClient(get()).getClient() }
    single<Flavor> { getFlavor() }
    single<ConfigFile> { getClientConfig() }

    factoryOf(::NetworkControllerKtor) { bind<NetworkController>() }
    singleOf(::TokenPersistence)
    singleOf(::AccessPersistence)
    singleOf(::DefaultDataRepository) { bind<DataRepository>() }
    single { params -> Navigation(params.get(), params.get()) }
    singleOf(::MultiPlatformSettingsPersistence) { bind<Persistence>() }

    singleOf(::AppViewModel)
    singleOf(::LoginViewModel)
    singleOf(::AdminHomeViewModel)
    singleOf(::MerchantDemoManagerViewModel)
    singleOf(::NameIdLookupViewModel)
    singleOf(::CoalitionCreatorViewModel)
    singleOf(::TerminalInfoViewModel)
    singleOf(::MerchantActivityViewModel)
    singleOf(::LocationNearbyGroupManagerViewModel)
}