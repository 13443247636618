package admin.ui.dialogs

import admin.ui.AppViewModel
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue

@Composable
fun HandleDialogs(viewModel: AppViewModel) {
    val _dialogState by viewModel.dialogStateStateFlow.collectAsState()
    val dialogState = _dialogState

    when (dialogState) {
        is AppViewModel.DialogState.Nothing -> Unit
        is AppViewModel.DialogState.LogOutConfirmation -> {
            LogOutDialog(dialogState)
        }
    }
}