package com.enrollandpay

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.foundation.shape.GenericShape
import androidx.compose.foundation.text.ClickableText
import androidx.compose.foundation.text.selection.SelectionContainer
import androidx.compose.material.ExperimentalMaterialApi
import androidx.compose.material.MaterialTheme
import androidx.compose.material.Text
import androidx.compose.material.pullrefresh.PullRefreshIndicator
import androidx.compose.material.pullrefresh.pullRefresh
import androidx.compose.material.pullrefresh.rememberPullRefreshState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalLifecycleOwner
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.lifecycle.Lifecycle
import model.SmsFullInformation

val TriangleShape = GenericShape { size, _ ->
    moveTo(size.width / 2f, size.height)
    lineTo((size.width * 1.5f), 0f)
    lineTo((size.width * -.5f), 0f)
}
@Composable
fun MessagesScreen(viewModel: MessagingViewModel) {
    refreshOnResume(viewModel)

    messages(viewModel)
}

@OptIn(ExperimentalMaterialApi::class)
@Composable
private fun messages(viewModel: MessagingViewModel) {
    val messages by viewModel.messagesStateFlow.collectAsState()

    val pullRefreshState = rememberPullRefreshState(
        refreshing = messages == null,
        onRefresh = viewModel::lookupMessages
    )

    val listState = rememberLazyListState()

    LaunchedEffect(messages) {
        messages?.size?.takeIf { it > 0 }?.let {
            listState.scrollToItem(index = it - 1)
        }
    }


    Box(Modifier.fillMaxWidth()) {
        LazyColumn(
            modifier = Modifier.pullRefresh(pullRefreshState),
            state = listState,
        ) {
            items(messages?.reversed() ?: emptyList()) {
                ChatMessage(it)
            }
        }
        PullRefreshIndicator(
            refreshing = messages == null,
            state = pullRefreshState,
            modifier = Modifier.align(Alignment.TopCenter),
        )
    }
}

@Composable
private fun refreshOnResume(viewModel: MessagingViewModel) {
    val currentLifecycleState by LocalLifecycleOwner.current.lifecycle.currentStateFlow.collectAsState()

    when (currentLifecycleState) {
        Lifecycle.State.RESUMED -> viewModel.lookupMessages()
        else -> Unit
    }
}

@Composable
fun ChatMessage(message: SmsFullInformation) {
    val isSentByMe = false
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(8.dp),
        horizontalArrangement = if (isSentByMe) Arrangement.End else Arrangement.Start
    ) {
        if (isSentByMe) {
            ChatBubble(message.body, Color.Blue, Color.White, message.sentTime.toString())
        } else {
            ChatBubble(message.body, Color.Gray, Color.Black, message.sentTime.toString())
        }
    }
}

@Composable
fun ChatBubble(content: String, backgroundColor: Color, textColor: Color, timestamp: String) {
    Column(
        modifier = Modifier
            .padding(horizontal = 4.dp),
        horizontalAlignment = if (backgroundColor == Color.Blue) Alignment.End else Alignment.Start
    ) {
        Column(
            modifier = Modifier
                .background(backgroundColor, shape = MaterialTheme.shapes.medium)
                .padding(8.dp)
//                .widthIn(max = 250.dp) // Adjust the width as needed
                .then(
                    if (backgroundColor == Color.Blue) {
                        Modifier.padding(end = 16.dp) // Adding space for the tail
                    } else {
                        Modifier.padding(start = 16.dp) // Adding space for the tail
                    }
                )
        ) {
            SelectionContainer {
                LinkTextFromStringWithParsedLinks(content)
            }
            Spacer(modifier = Modifier.height(4.dp)) // Spacer for spacing between text and timestamp
            Text(
                text = timestamp,
                color = textColor.copy(alpha = 0.7f),
                fontSize = 12.sp,
                style = MaterialTheme.typography.body2
            )
        }

        // Tail of the bubble
        Box(
            modifier = Modifier
                .padding(horizontal = 10.dp)
                .background(backgroundColor,TriangleShape)
                .size(10.dp)
        )
    }
}

data class LinkTextData(
    val text: String,
    val tag: String? = null,
    val annotation: String? = null,
    val onClick: ((str: AnnotatedString.Range<String>) -> Unit)? = null,
)

@Composable
fun LinkTextFromStringWithParsedLinks(content: String) {
    val urlRegex = Regex("""https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)""")
    val results = urlRegex.findAll(content)
    val linkTextData = mutableListOf<LinkTextData>()
    val uriHandler = LocalUriHandler.current
    if (results.count() > 0) {
        var cursor = 0
        results.forEach { result ->
            if (cursor < result.range.first) {
                linkTextData.add(LinkTextData(content.substring(cursor, result.range.first)))
            }
            result.value.let {
                linkTextData.add(LinkTextData(
                    text = it,
                    tag = it,
                    annotation = it,
                    onClick = { uriHandler.openUri(it.item) },
                ))
            }
            cursor = result.range.last + 1
        }
        if (cursor < content.length) {
            linkTextData.add(LinkTextData(content.substring(cursor, content.length)))
        }
    } else {
        linkTextData.add(LinkTextData(content))
    }
    LinkText(linkTextData)
}

@Composable
fun LinkText(
    linkTextData: List<LinkTextData>,
    modifier: Modifier = Modifier,
) {
    val annotatedString = createAnnotatedString(linkTextData)

    ClickableText(
        text = annotatedString,
        style = MaterialTheme.typography.body1,
        onClick = { offset ->
            linkTextData.forEach { annotatedStringData ->
                if (annotatedStringData.tag != null && annotatedStringData.annotation != null) {
                    annotatedString.getStringAnnotations(
                        tag = annotatedStringData.tag,
                        start = offset,
                        end = offset,
                    ).firstOrNull()?.let {
                        annotatedStringData.onClick?.invoke(it)
                    }
                }
            }
        },
        modifier = modifier,
    )
}

@Composable
private fun createAnnotatedString(data: List<LinkTextData>): AnnotatedString {
    return buildAnnotatedString {
        data.forEach { linkTextData ->
            if (linkTextData.tag != null && linkTextData.annotation != null) {
                pushStringAnnotation(
                    tag = linkTextData.tag,
                    annotation = linkTextData.annotation,
                )
                withStyle(
                    style = SpanStyle(
                        color = MaterialTheme.colors.primary,
                        textDecoration = TextDecoration.Underline,
                    ),
                ) {
                    append(linkTextData.text)
                }
                pop()
            } else {
                append(linkTextData.text)
            }
        }
    }
}

//fun String.asAnnotatedStringWithParsedLinks(): AnnotatedString = buildAnnotatedString {
//    val urlRegex = Regex("""https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)""")
//    val content = this@asAnnotatedStringWithParsedLinks
//    val results = urlRegex.findAll(content)
//    if (results.count() > 0) {
//        var cursor = 0
//        results.forEach { result ->
//            if (cursor < result.range.first) {
//                append(content.substring(cursor, result.range.first))
//            }
//            result.value.let {
//                withLink(
//                    LinkAnnotation.Url(
//                        it,
//                        TextLinkStyles(style = SpanStyle(color = Color.Blue))
//                    )
//                ) {
//                    append(it)
//                }
//            }
//            cursor = result.range.last + 1
//        }
//        if (cursor < content.length) {
//            append(content.substring(cursor, content.length))
//        }
//    } else {
//        append(content)
//    }
//}