package ui.consumerInformation

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material.Card
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.semantics.clearAndSetSemantics
import androidx.compose.ui.semantics.contentDescription
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import formatDecimalToCurrency
import kotlinx.datetime.FixedOffsetTimeZone
import kotlinx.datetime.TimeZone
import kotlinx.datetime.UtcOffset
import kotlinx.datetime.toInstant
import kotlinx.datetime.toLocalDateTime
import model.Card
import model.CardType
import model.DeleteCard
import orZero
import org.jetbrains.compose.resources.painterResource
import toContentDescription
import toDateHourFormat
import ui.Colors.Companion.TransactionBorderStroke

@Composable
fun CardDisplayView(item: Card, cardViewHeight: Dp) {
    val cardVerticalPadding = 8.dp
    Card(
        modifier = Modifier
            .size(width = 100.dp, height = cardViewHeight - (cardVerticalPadding * 2))
            .padding(vertical = cardVerticalPadding)
            .clearAndSetSemantics {
                contentDescription =
                    toContentDescription(
                        CardType.getCardType(item.panMasked?.substring(0, 1)),
                        "Card ending in",
                        item.panMasked!!.takeLast(3)
                    )
            },
        border = BorderStroke(1.dp, TransactionBorderStroke)
    ) {
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = Modifier
                .padding(horizontal = 4.dp)
        ) {
            val image = CardType.getDrawable(item.panMasked?.substring(0, 1))
            Image(
                painter = painterResource(image),
                contentDescription = null,
                modifier = Modifier.size(70.dp)
            )
            if (item.panMasked != null) {
                Text(item.panMasked!!.takeLast(8))
            }
        }
    }
}

@Composable
fun DeleteCardDisplayView(
    item: DeleteCard,
    onCardClicked: () -> Unit,
) {
    val cardVerticalPadding = 8.dp
    androidx.compose.material3.Card(
        modifier = Modifier
            .padding(vertical = cardVerticalPadding, horizontal = 16.dp)
            .fillMaxWidth()
            .clearAndSetSemantics {
                contentDescription =
                    toContentDescription(
                        CardType.getCardType(item.card.panMasked?.substring(0, 1)),
                        "Card ending in",
                        item.card.panMasked!!.takeLast(3)
                    )
            },
        border = BorderStroke(1.dp, TransactionBorderStroke)
    ) {
        Row(
            modifier = Modifier
                .clickable {
                    onCardClicked()
                }
                .fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        ) {
            LeftCard(item.card)
            RightCard(item)
        }
    }
}

@Composable
private fun RightCard(item: DeleteCard) {
    Column(modifier = Modifier) {
        item.consumerPhoneNumber?.let {
            Text("Phone Number: $it")
        }
        item.amountTotal?.let {
            Text("Amount total: ${it.formatDecimalToCurrency()}")
        }
        item.updateDateTime?.let {
            val dateAndTime = it.toInstant(TimeZone.UTC)
                .toLocalDateTime(
                    FixedOffsetTimeZone(UtcOffset(minutes = (item.locationUtcOffsetHour.orZero() * 60).toInt()))
                ).toDateHourFormat()
            val date = dateAndTime.first
            val time = dateAndTime.second
            Text("$date $time", fontWeight = FontWeight.Medium)
        }
    }
}

@Composable
private fun LeftCard(item: Card) {
    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        modifier = Modifier
            .padding(horizontal = 16.dp, vertical = 8.dp)
    ) {
        val image = CardType.getDrawable(item.panMasked?.substring(0, 1))
        Image(
            painter = painterResource(image),
            contentDescription = null,
            modifier = Modifier.size(70.dp)
        )
        item.panMasked?.let{
            Text(item.panMasked!!.takeLast(8))
        }
    }
}