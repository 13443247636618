package ui.consumerInformation

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Person
import androidx.compose.material.icons.filled.Phone
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.semantics.clearAndSetSemantics
import androidx.compose.ui.semantics.contentDescription
import androidx.compose.ui.unit.dp
import linx.linxapp.generated.resources.Res
import linx.linxapp.generated.resources.valued_customer
import model.ConsumerInformationV3
import org.jetbrains.compose.resources.stringResource
import toContentDescription
import toPhoneNumberFormat
import toSingleCharacterContentDescription
import ui.Colors

@Composable
fun ConsumerInformationDisplay(consumerInformation: ConsumerInformationV3?) {
    consumerInformation?.consumer?.let {
        val contact = consumerInformation.consumerContact
        Card(
            colors = CardDefaults.cardColors(
                containerColor = Colors.Default
            ),
            modifier = Modifier.fillMaxWidth().padding(horizontal = 16.dp),
        ) {

            val name = "${it.nameFirst.orEmpty()} ${it.nameLast.orEmpty()}".takeIf { it.isNotBlank() } ?: stringResource(Res.string.valued_customer)
            Column(
                modifier = Modifier
                    .padding(16.dp)
                    .clearAndSetSemantics {
                        contentDescription = toContentDescription(
                            "Consumer Information",
                            name,
                            contact?.phoneNumber?.toSingleCharacterContentDescription() ?: "",
                            it.birthDate?.toString() ?: ""
                        )
                    }
            ) {
                TextComposableWithIcon(name, Icons.Filled.Person)
                if (contact?.phoneNumber != null && contact.phoneNumber != "")
                    TextComposableWithIcon(contact.phoneNumber!!.toPhoneNumberFormat(), Icons.Filled.Phone,)
                if (it.birthDate != null)
                    TextComposableWithIcon(it.birthDate.toString(), Icons.Filled.Phone)
            }
        }
    }
}

@Composable
fun TextComposableWithIcon(
    text: String,
    imageVector: ImageVector,
) {
    Row {
        Icon(
            imageVector = imageVector,
            contentDescription = null,
            modifier = Modifier.size(24.dp)
        )
        Text(text)
    }
}