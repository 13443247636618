package navigation

import model.DataRepository
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

sealed class NavigationState(
    val title: String,
    val poppable: Boolean = true,
    val guid: String,
) {
    class Blank : NavigationState("", false, "")
    class Login : NavigationState("Login", false, "")
    class LoginSubmitted : NavigationState("", false, "")
    class Auth(authGuid: String) : NavigationState("Auth", false, authGuid)
    class AuthFailed : NavigationState("", false, "")
    class ConsumerInformation : NavigationState("ConsumerInformation", false, "")
    class MerchantInformation(consumerLoyaltyProgramGuid: String) : NavigationState("MerchantInformation", guid = consumerLoyaltyProgramGuid)
    class EReceipt(orderPaymentGuid: String) : NavigationState("EReceipt", guid = orderPaymentGuid)
    class TermsOfService : NavigationState("TermsOfService", guid = "")
    class PrivacyPolicy : NavigationState("PrivacyPolicy", guid = "")
    class Search : NavigationState("Search", guid = "")
    class Exit : NavigationState("Exit", false, "")
    class SEReceipt(orderPaymentGuid: String) : NavigationState("SEReceipt", guid = orderPaymentGuid)
    class DemoMerchantAdmin(consumerLoyaltyProgramGuid: String) : NavigationState("DemoMerchantAdmin", guid = consumerLoyaltyProgramGuid)

    companion object: KoinComponent {
        val dataRepository: DataRepository by inject()

        fun fromHash(hash: String): NavigationState? {
            val params: Map<String, String> = hash.split("&").filterNot { it.isEmpty() }.associate {
                it.split("=").run {
                    first() to last()
                }
            }.onEach { println("Param: ${it.key} = ${it.value}") }

            val location = params.keys.firstOrNull() ?: "Login"
            var guid: String? = null
            params[location]?.let {
                if (it.isNotBlank() && it != location) {
                    guid = it
                }
            }

            val isFullScreen = params["f"].toBoolean()
            dataRepository.setAppFullScreen(isFullScreen)

            return when (location.lowercase()) {
                "Login".lowercase() -> Login()
                "Auth".lowercase() -> guid?.let { Auth(it) } ?: Login()
                "ConsumerInformation".lowercase() -> ConsumerInformation()
                "MerchantInformation".lowercase() -> guid?.let { MerchantInformation(it) } ?: Login()
                "EReceipt".lowercase() -> guid?.let { EReceipt(it) } ?: Login()
                "TermsOfService".lowercase() -> TermsOfService()
                "PrivacyPolicy".lowercase() -> PrivacyPolicy()
                "Search".lowercase() -> Search()
                "SEReceipt".lowercase() -> guid?.let { SEReceipt(it) } ?: Blank()
                "DemoMerchantAdmin".lowercase() -> guid?.let { DemoMerchantAdmin(it) }
                else -> null.also { println("Invalid location in: '$hash'") }
            }
        }
    }

    fun asUrlString(): String {
        return when {
            this is Login -> ""
            this is ConsumerInformation
                || this is Blank
                || this is AuthFailed
                || this is LoginSubmitted
                || this is TermsOfService
                || this is PrivacyPolicy
                || this is Exit
                || this is Search
                -> title
            else -> """${title}=${guid}"""
        }
            .let { if (dataRepository.showAppFullScreen.value && this !is Blank) "$it&f=true" else it }
            .also { println("asUrlString: $it") }
    }

    override fun toString(): String {
        return "NavigationState(title='$title', poppable=$poppable, guid='$guid')"
    }

    override fun equals(other: Any?): Boolean {
        if (other is NavigationState) {
            if (title == other.title && guid == other.guid) {
                return true
            }
        }
        return false
    }
}