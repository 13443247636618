package admin.ui.coalitionCreator

import admin.model.DataRepository
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.stateIn
import kotlinx.coroutines.launch
import model.AdminAccess
import model.CoalitionAdminUser
import model.PopulatedCoalition

class CoalitionCreatorViewModel(
    private val dataRepository: DataRepository,
    private val scope: CoroutineScope,
) : ViewModel() {

    sealed class DialogState() {
        class None : DialogState()
        class Success : DialogState()
        class Error : DialogState()
    }

    init {
        getCoalitionUsers()
    }

    val coalitionsStateFlow = dataRepository.populatedCoalitionsStateFlow

    val coalitionLongNameMutableStateFlow = MutableStateFlow("")
    val coalitionLongNameStateFlow = coalitionLongNameMutableStateFlow.asStateFlow()

    val coalitionShortNameMutableStateFlow = MutableStateFlow("")
    val coalitionShortNameStateFlow = coalitionShortNameMutableStateFlow.asStateFlow()

    private val isSubmittingMutableStateFlow = MutableStateFlow(false)

    val isButtonEnabledStateFlow =
        combine(
            isSubmittingMutableStateFlow,
            coalitionLongNameMutableStateFlow,
            coalitionShortNameMutableStateFlow
        )
        { isSubmitting, coalitionLongName, coalitionShortName ->
            !isSubmitting && coalitionLongName.isNotBlank() && coalitionShortName.isNotBlank()
        }.stateIn(scope, SharingStarted.WhileSubscribed(), false)

    private val dialogStateMutableStateFlow = MutableStateFlow<DialogState>(DialogState.None())
    val dialogStateStateFlow = dialogStateMutableStateFlow.asStateFlow()

    fun onCoalitionLongNameChanged(coalitionLongName: String) {
        coalitionLongNameMutableStateFlow.value = coalitionLongName
    }

    fun onCoalitionShortNameChanged(coalitionShortName: String) {
        coalitionShortNameMutableStateFlow.value = coalitionShortName
    }

    fun submitCoalition() {
        if (!isSubmittingMutableStateFlow.value) {
            isSubmittingMutableStateFlow.value = true
            viewModelScope.launch {
                val result =
                    dataRepository.submitCoalition(coalitionLongNameMutableStateFlow.value, coalitionShortNameMutableStateFlow.value)
                if (result) {
                    dialogStateMutableStateFlow.value = DialogState.Success()
                } else {
                    dialogStateMutableStateFlow.value = DialogState.Error()
                }
                isSubmittingMutableStateFlow.value = false
            }
        }
    }

    fun dismissDialog() {
        dialogStateMutableStateFlow.value = DialogState.None()
        coalitionLongNameMutableStateFlow.value = ""
        coalitionShortNameMutableStateFlow.value = ""
    }

    fun isFullAdmin(): Boolean {
        return dataRepository.accessLevel == AdminAccess.FullAdmin
    }

    private val coalitionAdminUsersMutableStateFlow = MutableStateFlow<List<CoalitionAdminUser>>(emptyList())

    val coalitionsWithUsers = combine(
        dataRepository.populatedCoalitionsStateFlow,
        coalitionAdminUsersMutableStateFlow
    ) { coalitions, users ->
        println("coalitions: $coalitions")
        coalitions.populatedCoalitions.map { coalition ->
            CoalitionWithUser(
                coalition = coalition,
                user = users.firstOrNull { it.coalitionId == coalition.id }
            )
        }
    }.stateIn(scope, SharingStarted.WhileSubscribed(), emptyList())

    data class CoalitionWithUser(
        val coalition: PopulatedCoalition,
        val user: CoalitionAdminUser?,
    )

    fun getCoalitionUsers() {
        viewModelScope.launch {
            try {
                println("Getting coalition users")
                val temp = dataRepository.getCoalitionAdminUsers()
                println("Coalition users: $temp")
                coalitionAdminUsersMutableStateFlow.value = temp
                println("Coalition users: ${coalitionAdminUsersMutableStateFlow.value}")
            } catch (e: Exception) {
                println("Error getting coalition users: ${e.message}")
                e.printStackTrace()
            }
        }
    }
}