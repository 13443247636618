package ui.eReceipt

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.text.ClickableText
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.TextUnit
import androidx.compose.ui.unit.dp
import receipt.OptInTypes
import receipt.hasFlag

@Composable
fun Links(
    isTokenAvailable: Boolean,
    currentCustomerIdMatchesExistingCustomerId: Boolean,
    maxWidth: Dp,
    consumerOptInType: Int?,
    onCancelEnrollmentClicked: () -> Unit,
    onTermsOfServiceClicked: () -> Unit,
    onPrivacyPolicyClicked: () -> Unit,
    onManageAccountClicked: () -> Unit,
) {
    Column(
        modifier = Modifier.widthIn(0.dp, maxWidth),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Spacer(modifier = Modifier.height(8.dp))
        if (isTokenAvailable && !currentCustomerIdMatchesExistingCustomerId) {
            if(!consumerOptInType.hasFlag(OptInTypes.OptedOut)) {
                ClickableText(
                    text = buildAnnotatedString {
                        withStyle(
                            style = SpanStyle(
                                color = Color.Blue,
                                fontSize = TextUnit.Unspecified,
                                fontWeight = FontWeight.Normal,
                                textDecoration = TextDecoration.None
                            )
                        ) { append("Cancel Enrollment") }
                    },
                    onClick = { onCancelEnrollmentClicked() }
                )
                Spacer(modifier = Modifier.height(8.dp))
            }
        } else {
            var authRequested = false
            ClickableText(
                text = buildAnnotatedString {
                    withStyle(
                        style = SpanStyle(
                            color = Color.Blue,
                            fontSize = TextUnit.Unspecified,
                            fontWeight = FontWeight.Normal,
                            textDecoration = TextDecoration.None
                        )
                    ) { append("Manage Account") }
                },
                onClick = {
                    if (!authRequested) {
                        onManageAccountClicked()
                        authRequested = true
                    }
                },
            )
            Spacer(modifier = Modifier.height(8.dp))
        }
        HyperlinkText(
            fullText = "Refer a Business",
            hyperLinks = "https://www.enrollandpay.com/contact/"
        )
        Spacer(modifier = Modifier.height(8.dp))
        PrivacyAndTermsOfService(onPrivacyPolicyClicked, onTermsOfServiceClicked)
        Spacer(modifier = Modifier.height(8.dp))
        HyperlinkText(
            fullText = "Enroll & pay",
            hyperLinks = "https://www.enrollandpay.com/",
            linkTextColor = Color.Black
        )
        Spacer(modifier = Modifier.height(8.dp))
    }
}