@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package linx.adminportal.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.DrawableResource

private object CommonMainDrawable0 {
  public val american_express_svgrepo_com: DrawableResource by 
      lazy { init_american_express_svgrepo_com() }

  public val app_icon: DrawableResource by 
      lazy { init_app_icon() }

  public val compose_multiplatform: DrawableResource by 
      lazy { init_compose_multiplatform() }

  public val ep_logo: DrawableResource by 
      lazy { init_ep_logo() }

  public val former_visa__company__logo: DrawableResource by 
      lazy { init_former_visa__company__logo() }

  public val ic_android_black_24dp: DrawableResource by 
      lazy { init_ic_android_black_24dp() }

  public val mastercard_light: DrawableResource by 
      lazy { init_mastercard_light() }

  public val primary_logo_final: DrawableResource by 
      lazy { init_primary_logo_final() }

  public val thumb_nail_up: DrawableResource by 
      lazy { init_thumb_nail_up() }

  public val us: DrawableResource by 
      lazy { init_us() }

  public val visa_color: DrawableResource by 
      lazy { init_visa_color() }
}

internal val Res.drawable.american_express_svgrepo_com: DrawableResource
  get() = CommonMainDrawable0.american_express_svgrepo_com

private fun init_american_express_svgrepo_com(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:american_express_svgrepo_com",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/linx.adminportal.generated.resources/drawable/american_express_svgrepo_com.xml", -1, -1),
    )
)

internal val Res.drawable.app_icon: DrawableResource
  get() = CommonMainDrawable0.app_icon

private fun init_app_icon(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:app_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/linx.adminportal.generated.resources/drawable/app_icon.xml", -1, -1),
    )
)

internal val Res.drawable.compose_multiplatform: DrawableResource
  get() = CommonMainDrawable0.compose_multiplatform

private fun init_compose_multiplatform(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:compose_multiplatform",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/linx.adminportal.generated.resources/drawable/compose-multiplatform.xml", -1, -1),
    )
)

internal val Res.drawable.ep_logo: DrawableResource
  get() = CommonMainDrawable0.ep_logo

private fun init_ep_logo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ep_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/linx.adminportal.generated.resources/drawable/ep_logo.png", -1, -1),
    )
)

internal val Res.drawable.former_visa__company__logo: DrawableResource
  get() = CommonMainDrawable0.former_visa__company__logo

private fun init_former_visa__company__logo(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:former_visa__company__logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/linx.adminportal.generated.resources/drawable/former_visa__company__logo.xml", -1, -1),
    )
)

internal val Res.drawable.ic_android_black_24dp: DrawableResource
  get() = CommonMainDrawable0.ic_android_black_24dp

private fun init_ic_android_black_24dp(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_android_black_24dp",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/linx.adminportal.generated.resources/drawable/ic_android_black_24dp.xml", -1, -1),
    )
)

internal val Res.drawable.mastercard_light: DrawableResource
  get() = CommonMainDrawable0.mastercard_light

private fun init_mastercard_light(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:mastercard_light",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/linx.adminportal.generated.resources/drawable/mastercard_light.xml", -1, -1),
    )
)

internal val Res.drawable.primary_logo_final: DrawableResource
  get() = CommonMainDrawable0.primary_logo_final

private fun init_primary_logo_final(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:primary_logo_final",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/linx.adminportal.generated.resources/drawable/primary_logo_final.xml", -1, -1),
    )
)

internal val Res.drawable.thumb_nail_up: DrawableResource
  get() = CommonMainDrawable0.thumb_nail_up

private fun init_thumb_nail_up(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:thumb_nail_up",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/linx.adminportal.generated.resources/drawable/thumb_nail_up.xml", -1, -1),
    )
)

internal val Res.drawable.us: DrawableResource
  get() = CommonMainDrawable0.us

private fun init_us(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:us",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/linx.adminportal.generated.resources/drawable/us.xml", -1, -1),
    )
)

internal val Res.drawable.visa_color: DrawableResource
  get() = CommonMainDrawable0.visa_color

private fun init_visa_color(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:visa_color",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/linx.adminportal.generated.resources/drawable/visa_color.xml", -1, -1),
    )
)
