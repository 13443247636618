import admin.ui.login.ExternalLoginControls
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.dom.addClass
import kotlinx.dom.removeClass
import org.w3c.dom.Document
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLSpanElement

class HtmlExternalLoginControls(document: Document): ExternalLoginControls {
    private val emailValueMutableStateFlow = MutableStateFlow("")
    override val emailValueStateFlow = emailValueMutableStateFlow.asStateFlow()
    private val passwordValueMutableStateFlow = MutableStateFlow("")
    override val passwordValueStateFlow = passwordValueMutableStateFlow.asStateFlow()
    private val submitRequestEventMutableStateFlow =
        MutableStateFlow(ExternalLoginControls.SubmitRequestEvent(true))
    override val submitRequestEventStateFlow = submitRequestEventMutableStateFlow.asStateFlow()

    private val bodyDiv = document.getElementById("body")
    private val emailInput = (document.createElement("input") as HTMLInputElement).apply {
        type = "email"
        autocomplete = "on"
        style.apply {
            boxSizing = "border-box"
            color = "#495057"
            fontSize = "1rem"
            padding = "0.5rem 0.5rem"
        }
    }
    private val passwordDiv = (document.createElement("div") as HTMLDivElement)
    private val passwordInput = (document.createElement("input") as HTMLInputElement).apply {
        type = "password"
        autocomplete = "on"
        style.apply {
            boxSizing = "border-box"
            color = "#495057"
            fontSize = "1rem"
            padding = "0.5rem 0.5rem"
            width = "100%"
            height = "100%"
        }
    }
    private val toggleEye = (document.createElement("span") as HTMLSpanElement).apply {
        onclick = {
            if (passwordInput.getAttribute("type") == "password") {
                passwordInput.setAttribute("type", "text")
                removeClass("fa-eye")
                addClass("fa-eye-slash")
            } else {
                passwordInput.setAttribute("type", "password")
                removeClass("fa-eye-slash")
                addClass("fa-eye")
            }
        }
        className = "fa fa-fw fa-eye"
        style.apply {
            color = "#607389"
            cssFloat = "right"
            marginRight = "4px"
            marginTop = "-25px"
            position = "relative"
            zIndex = "2"
        }
    }
    init {
        bodyDiv?.appendChild(emailInput)

        passwordDiv.apply {
            appendChild(passwordInput)
            appendChild(toggleEye)
        }
        bodyDiv?.appendChild(passwordDiv)

        emailInput.oninput = { event ->
            (event.target as HTMLInputElement).value.also { emailValueMutableStateFlow.value = it }
        }
        passwordInput.oninput = { event ->
            (event.target as HTMLInputElement).value.also { passwordValueMutableStateFlow.value = it }
        }
        passwordInput.onkeypress = { e ->
            if (e.key == "Enter" || e.keyCode == 13) {
                submitRequestEventMutableStateFlow.value =
                    ExternalLoginControls.SubmitRequestEvent()
            }
        }
    }

    override fun onEmailChanged(email: String) {
        if (email != emailInput.value) {
            emailInput.value = email
        }
    }

    override fun onPasswordChanged(password: String) {
        if (password != passwordInput.value) {
            passwordInput.value = password
        }
    }

    override fun onPlaceEmail(x: Int, y: Int, width: Int, height: Int) {
        emailInput.style.apply {
            position = "absolute"
            top = "${y}px"
            left = "${x}px"
            this.width = "${width}px"
            this.height = "${height}px"
        }
    }

    override fun onPlacePassword(x: Int, y: Int, width: Int, height: Int) {
        passwordDiv.style.apply {
            position = "absolute"
            top = "${y}px"
            left = "${x}px"
            this.width = "${width}px"
            this.height = "${height}px"
        }
        toggleEye.style.apply {
            marginTop = "-${(height/2)+8}px"
        }
    }

    override fun hideControls() {
        println("Hide")
        emailInput.style.display = "none"
        passwordDiv.style.display = "none"
    }

    override fun showControls() {
        emailInput.style.display = "block"
        passwordDiv.style.display = "block"
    }
}