package admin.ui.dialogs

import admin.clearSemantics
import admin.ui.Colors
import androidx.compose.foundation.layout.widthIn
import androidx.compose.material.AlertDialog
import androidx.compose.material.Text
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp

@Composable
fun SuccessCoalitionCreatorDialog(
    onDismiss: () -> Unit,
) {
    AlertDialog(
        modifier = Modifier.widthIn(280.dp, 320.dp),
        title = { Text("Success creating the coalition", modifier = Modifier.clearSemantics()) },
        onDismissRequest = onDismiss,
        confirmButton = {
            Button(
                onClick = onDismiss,
                colors = ButtonDefaults.buttonColors(
                    containerColor = Colors.Secondary
                )
            ) {
                Text("OK", color = Color.White)
            }
        },
    )
}