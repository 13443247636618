package admin

import admin.navigation.NavigationState
import admin.ui.AppViewModel
import admin.ui.Colors
import admin.ui.home.AdminHome
import admin.ui.login.ExternalLoginControls
import admin.ui.login.LoginRequest
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier

@Composable
fun AppContent(
    viewModel: AppViewModel,
    locationListener: (String) -> Unit,
    externalLoginControls: ExternalLoginControls?,
) {
    val _navigationState by viewModel.navigationStateFlow.collectAsState()
    val navigationState = _navigationState
    println("NavigationState: $navigationState")
    locationListener(navigationState.asUrlString())

    when (navigationState) {
        is NavigationState.Blank -> {
            Box(Modifier.fillMaxSize().background(Colors.Default))
        }

        is NavigationState.Login -> {
            LoginRequest(onSubmitted = {}, externalLoginControls = externalLoginControls)
        }

        is NavigationState.AdminHome,
        is NavigationState.MerchantDemoManager,
        is NavigationState.NameId,
        is NavigationState.LinxSmsIntercept,
        is NavigationState.CoalitionCreator,
        is NavigationState.TerminalInfo,
        is NavigationState.MerchantActivity,
        is NavigationState.LocationNearbyGroupManager,
        -> {
            AdminHome()
        }

        is NavigationState.Exit -> {
            Box(Modifier.fillMaxSize().background(Colors.Default))
        }

    }
}