package admin.ui

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import model.PopulatedCoalition

@Composable
fun CoalitionListItemView(coalition: PopulatedCoalition, showId: Boolean = false, drawSeparator: Boolean = false, modifier: Modifier = Modifier) {
    ListItemView(coalition.name, coalition.id.takeIf { showId }, 16.dp, modifier.then(
        if (drawSeparator) Modifier.drawBehind {
            val strokeWidth = 1 * density
            drawLine(
                Color.LightGray,
                Offset(0f, 2f),
                Offset(size.width, 2f),
                strokeWidth
            )
        } else {
            Modifier
        }
    ))
}
