package admin

import admin.model.ClientNavRequestEvent
import admin.ui.AppViewModel
import admin.ui.Colors
import admin.ui.dialogs.HandleDialogs
import admin.ui.login.ExternalLoginControls
import admin.ui.snackbars.HandleSnackBars
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material.MaterialTheme
import androidx.compose.material.Scaffold
import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import org.koin.compose.KoinApplication
import org.koin.compose.koinInject
import org.koin.core.context.stopKoin


@Composable
fun AdminApp(
    clientNavRequestStateFlow: StateFlow<ClientNavRequestEvent> = MutableStateFlow(ClientNavRequestEvent.Nothing),
    locationListener: (String) -> Unit = {},
    titleListener: (String) -> Unit = {},
    externalLoginControls: ExternalLoginControls? = null,
) {
    KoinApplication(application = {
        modules(appModules)
    }) {
        DisposableEffect(null) {
            onDispose {
                stopKoin()
            }
        }
        val viewModel: AppViewModel = koinInject()
        titleListener(viewModel.clientName)
        MaterialTheme {
            Box(
                modifier = Modifier
                    .fillMaxSize()
                    .background(Colors.Default),
                contentAlignment = Alignment.Center
            ) {
                Scaffold(
                    snackbarHost = {
                        HandleSnackBars(viewModel.snackbarStateStateFlow)
                    },
                ) { _ ->
                    AppContent(viewModel, locationListener, externalLoginControls)
                }
            }
        }
        ClientNavigation(viewModel, clientNavRequestStateFlow, locationListener)
        HandleDialogs(viewModel)
    }
}

@Composable
private fun ClientNavigation(
    viewModel: AppViewModel,
    clientNavRequestStateFlow: StateFlow<ClientNavRequestEvent>,
    locationListener: (String) -> Unit,
) {
    val _clientNavRequest by clientNavRequestStateFlow.collectAsState()
    val clientNavRequest = _clientNavRequest
    if (!clientNavRequest.isRead) {
        when (clientNavRequest) {
            ClientNavRequestEvent.Nothing -> Unit
            is ClientNavRequestEvent.Back -> viewModel.popBackStack()
            is ClientNavRequestEvent.HashStateChangeEvent -> viewModel.hashStateChangeEvent(clientNavRequest.newHash, locationListener)
        }
    }
}