package admin.ui.login

import admin.globalCoroutineExceptionHandler
import admin.model.DataRepository
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import model.AdminLoginResponse

class LoginViewModel(
    private val dataRepository: DataRepository,
) : ViewModel() {
    sealed class DialogState() {
        class Nothing() : DialogState()
        class InvalidCredential(val onConfirm: () -> Unit) : DialogState()
    }

    private val dialogStateMutableStateFlow = MutableStateFlow<DialogState>(DialogState.Nothing())
    val dialogStateStateFlow = dialogStateMutableStateFlow.asStateFlow()

    private val emailMutableStateFlow = MutableStateFlow("")
    val emailStateFlow = emailMutableStateFlow.asStateFlow()

    private val passwordMutableStateFlow = MutableStateFlow("")
    val passwordStateFlow = passwordMutableStateFlow.asStateFlow()

    private val isButtonEnableMutableStateFlow = MutableStateFlow(false)
    val isButtonEnableStateFlow = isButtonEnableMutableStateFlow.asStateFlow()

    fun updateEmail(email: String) {
        emailMutableStateFlow.value = email
        isButtonEnableMutableStateFlow.value = email.isNotBlank() && passwordStateFlow.value.isNotBlank()
    }

    fun updatePassword(password: String) {
        passwordMutableStateFlow.value = password
        isButtonEnableMutableStateFlow.value = emailStateFlow.value.isNotBlank() && password.isNotBlank()
    }

    fun submitCredentials(onSubmitted: () -> Unit) {
        if (isButtonEnableStateFlow.value) {
            onSubmitted()
            viewModelScope.launch(globalCoroutineExceptionHandler + Dispatchers.Default) {
                val tokenResponse = dataRepository.submitCredentials(emailMutableStateFlow.value, passwordMutableStateFlow.value)
                when (tokenResponse) {
                    is AdminLoginResponse.Success -> dataRepository.setAccess(tokenResponse.response)
                    is AdminLoginResponse.Error -> {
                        dialogStateMutableStateFlow.value = DialogState.InvalidCredential(::dismissDialog)
                    }
                }
            }
        }
    }

    fun dismissDialog() {
        dialogStateMutableStateFlow.value = DialogState.Nothing()
    }

}

interface ExternalLoginControls {
    val emailValueStateFlow: StateFlow<String>
    val passwordValueStateFlow: StateFlow<String>

    class SubmitRequestEvent(read: Boolean = false) {
        var isRead: Boolean = read
            get() {
                val read = field
                field = true
                return read
            }
            private set
    }
    val submitRequestEventStateFlow: StateFlow<SubmitRequestEvent>

    fun onEmailChanged(email: String)
    fun onPasswordChanged(password: String)
    fun onPlaceEmail(x: Int, y: Int, width: Int, height: Int)
    fun onPlacePassword(x: Int, y: Int, width: Int, height: Int)
    fun hideControls()
    fun showControls()
}