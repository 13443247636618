package admin.ui.search

import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.Text
import androidx.compose.material3.OutlinedTextField
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.input.key.Key
import androidx.compose.ui.input.key.KeyEventType
import androidx.compose.ui.input.key.key
import androidx.compose.ui.input.key.onPreviewKeyEvent
import androidx.compose.ui.input.key.type
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.unit.dp

@Composable
fun SearchTextField(searchText: String?, onSearchTextChange: (String) -> Unit, modifier: Modifier = Modifier) {
    OutlinedTextField(
        value = searchText.orEmpty(),
        onValueChange = onSearchTextChange,
        label = {
            Text(text = "Search")
        },
        keyboardOptions = KeyboardOptions(keyboardType = KeyboardType.Text),
        shape = RoundedCornerShape(0.dp),
        modifier = modifier.onPreviewKeyEvent {
            when {
                (it.key == Key.Enter && it.type == KeyEventType.KeyUp) -> {
                    true
                }

                else -> {
                    false
                }
            }
        },
    )
}