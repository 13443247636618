package admin.ui.login

import admin.ui.Colors
import admin.ui.dialogs.InvalidCredentialsDialog
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.widthIn
import androidx.compose.material.Text
import androidx.compose.material3.Button
import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.boundsInWindow
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.dp
import linx.adminportal.generated.resources.Res
import linx.adminportal.generated.resources.ep_logo
import org.jetbrains.compose.resources.painterResource
import org.koin.compose.koinInject
import ui.CredentialsFields
import kotlin.math.roundToInt

@Composable
fun LoginRequest(
    loginViewModel: LoginViewModel = koinInject(),
    onSubmitted: () -> Unit,
    externalLoginControls: ExternalLoginControls? = null,
) {
    externalLoginControls?.apply {
        val email by emailValueStateFlow.collectAsState()
        val password by passwordValueStateFlow.collectAsState()
        val submitRequestEvent by submitRequestEventStateFlow.collectAsState()
        loginViewModel.updateEmail(email)
        loginViewModel.updatePassword(password)
        if (!submitRequestEvent.isRead) {
            loginViewModel.submitCredentials(onSubmitted)
        }
        LaunchedEffect(this) {
            showControls()
        }
        DisposableEffect(this) {
            onDispose {
                hideControls()
            }
        }
    }
    Column(
        modifier = Modifier
            .fillMaxSize()
            .background(Colors.Secondary)
        ,
    ) {
        Box(modifier = Modifier
                .fillMaxWidth()
                .padding(8.dp)
            ,
            contentAlignment = Alignment.CenterStart,
        ) {
            Image(painter = painterResource(Res.drawable.ep_logo),
                 contentDescription = "Enroll and Pay"
            )
        }
        Box(modifier = Modifier
                .background(Colors.Primary)
                .fillMaxWidth()
            ,
            contentAlignment = Alignment.Center,
        ) {
            Column(
                modifier = Modifier
                    .widthIn(min = 300.dp)
                    .fillMaxWidth(.4f)
                    .padding(16.dp)
                ,
                horizontalAlignment = Alignment.Start,
                verticalArrangement = Arrangement.spacedBy(16.dp),
            ) {
                val localDensity = LocalDensity.current
                Text("Login with your admin account")
                CredentialsFields(
                    emailStateFlow = loginViewModel.emailStateFlow,
                    passwordStateFlow = loginViewModel.passwordStateFlow,
                    onEmailChanged = loginViewModel::updateEmail,
                    onPasswordChanged = loginViewModel::updatePassword,
                    onEnterPressed = { loginViewModel.submitCredentials(onSubmitted) },
                    emailModifier = Modifier.fillMaxWidth().onGloballyPositioned {
                        with(localDensity) {
                            externalLoginControls?.apply {
                                onPlaceEmail(
                                    it.boundsInWindow().left.toPx(density),
                                    it.boundsInWindow().top.toPx(density),
                                    it.size.width.toPx(density),
                                    it.size.height.toPx(density),
                                )
                            }
                        }
                    },
                    passwordModifier = Modifier.fillMaxWidth().onGloballyPositioned {
                        with(localDensity) {
                            externalLoginControls?.apply {
                                onPlacePassword(
                                    it.boundsInWindow().left.toPx(density),
                                    it.boundsInWindow().top.toPx(density),
                                    it.size.width.toPx(density),
                                    it.size.height.toPx(density),
                                )
                            }
                        }
                    },
                )
                val isButtonEnable by loginViewModel.isButtonEnableStateFlow.collectAsState()
                Button(
                    onClick = { loginViewModel.submitCredentials(onSubmitted) },
                    enabled = isButtonEnable
                ) {
                    Text("LOGIN", color = if (isButtonEnable) Color.White else Color.Black)
                }
            }
        }
        Box(modifier = Modifier
            .background(Colors.Highlight)
            .height(16.dp)
            .fillMaxWidth()
        )
    }

    HandleLoginRequestDialogs(loginViewModel, externalLoginControls)
}

@Composable
fun HandleLoginRequestDialogs(loginViewModel: LoginViewModel, externalLoginControls: ExternalLoginControls? = null) {
    val _dialogState by loginViewModel.dialogStateStateFlow.collectAsState()
    val dialogState = _dialogState

    when (dialogState) {
        is LoginViewModel.DialogState.Nothing -> externalLoginControls?.showControls()
        is LoginViewModel.DialogState.InvalidCredential -> {
            externalLoginControls?.hideControls()
            InvalidCredentialsDialog(loginViewModel::dismissDialog)
        }
    }
}

fun Float.toPx(ratio: Float): Int {
    return (this / ratio).roundToInt()
}

fun Int.toPx(ratio: Float): Int {
    return (this / ratio).roundToInt()
}