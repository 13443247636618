package ui.consumerInformation

import androidx.compose.foundation.layout.height
import androidx.compose.foundation.lazy.LazyListScope
import androidx.compose.foundation.lazy.items
import androidx.compose.material.Divider
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import model.MerchantFullInformationV3
import receipt.OptInTypes
import receipt.hasFlag

fun LazyListScope.ConsumerStoreInformationDisplay(
    merchants: List<MerchantFullInformationV3>,
    merchantCardView: @Composable (MerchantFullInformationV3, (MerchantFullInformationV3) -> Unit) -> Unit,
    onMerchantClicked: (MerchantFullInformationV3) -> Unit,
) {
    items(
        merchants
            .sortedWith(
                compareBy{ it.optInType.hasFlag(OptInTypes.OptedOut) }
            )
            .filter { it.name?.isNotBlank() == true }
    ) {
        merchantCardView(it, onMerchantClicked)
        Divider(
            color = Color.White,
            modifier = Modifier
                .height(16.dp)
        )
    }

}