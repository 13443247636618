package ui.consumerInformation

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.material.Divider
import androidx.compose.material.ExperimentalMaterialApi
import androidx.compose.material.Text
import androidx.compose.material.pullrefresh.PullRefreshIndicator
import androidx.compose.material.pullrefresh.pullRefresh
import androidx.compose.material.pullrefresh.rememberPullRefreshState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import kotlinx.coroutines.flow.StateFlow
import model.Card
import model.DataRepository
import model.DeleteCard
import ui.scrollbar.LazyColumnScrollbar
import ui.scrollbar.LazyRowScrollbar

@Composable
fun ColumnScope.ConsumerCardDisplay(cards: List<Card>, shouldShowScrollbar: Boolean) {
    Text(
        "My Cards",
        fontWeight = FontWeight.ExtraBold,
        modifier = Modifier.align(Alignment.Start).padding(start = 16.dp, top = 16.dp),
        fontSize = 20.sp
    )
    val lazyLisState = rememberLazyListState()
    val cardViewHeight = 126.dp //Intrinsic Height can't be used with lazyRows at this time
    LazyRowScrollbar(
        listState = lazyLisState,
        alwaysShowScrollBar = shouldShowScrollbar,
        height = cardViewHeight,
    ) {
        LazyRow(
            state = lazyLisState,
        ) {
            item {
                Spacer(modifier = Modifier.width(16.dp))
            }
            items(cards) { item ->
                if (item.cardId != -1) {
                    CardDisplayView(item, cardViewHeight)
                    Divider(
                        color = Color.Transparent,
                        modifier = Modifier
                            .width(8.dp)
                    )
                }
            }
            item {
                Spacer(modifier = Modifier.width(8.dp))
            }
        }
    }
}

@OptIn(ExperimentalMaterialApi::class)
@Composable
fun ColumnScope.ConsumerCardDisplayColumn(
    cards: List<DeleteCard>,
    shouldShowScrollbar: Boolean,
    cardStateFlow: StateFlow<DataRepository.CardState>,
    onPullToRefresh: () -> Unit,
    onCardClicked: (Card) -> Unit,
) {
    Text(
        "Cards",
        fontWeight = FontWeight.ExtraBold,
        modifier = Modifier.align(Alignment.Start).padding(start = 16.dp, top = 16.dp),
        fontSize = 20.sp
    )

    Box(modifier = Modifier.fillMaxWidth()) {
        val lazyLisState = rememberLazyListState()
        val cardState by cardStateFlow.collectAsState()

        val pullRefreshState = rememberPullRefreshState(
            refreshing = cardState is DataRepository.CardState.Refreshing,
            onRefresh = onPullToRefresh
        )

        Column {
            LazyColumnScrollbar(
                listState = lazyLisState,
                alwaysShowScrollBar = shouldShowScrollbar,
            ) {
                LazyColumn(state = lazyLisState, modifier = Modifier.pullRefresh(pullRefreshState)) {
                    item {
                        Spacer(modifier = Modifier.width(16.dp))
                    }
                    items(cards.sortedByDescending { it.updateDateTime }) { item ->
                        if (item.card.cardId != -1) {
                            DeleteCardDisplayView(item) {
                                onCardClicked(item.card)
                            }
                            Divider(
                                color = Color.Transparent,
                                modifier = Modifier
                                    .width(8.dp)
                            )
                        }
                    }
                    item {
                        Spacer(modifier = Modifier.width(8.dp))
                    }
                }
            }
        }

        PullRefreshIndicator(
            refreshing = cardState is DataRepository.CardState.Refreshing,
            state = pullRefreshState,
            modifier = Modifier.align(Alignment.TopCenter),
        )

    }

}