package ui.demoMerchantAdmin

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import kotlinx.coroutines.flow.StateFlow
import model.Card
import model.DataRepository
import ui.consumerInformation.ConsumerCardDisplayColumn

@Composable
fun DemoMerchantAdminScreen(
    shouldShowScrollbar: Boolean,
    cardStateFlow: StateFlow<DataRepository.CardState>,
    onPullToRefresh: () -> Unit,
    onCardClicked: (Card) -> Unit,
) {
    Column(modifier = Modifier.fillMaxSize()) {
        val _cardList by cardStateFlow.collectAsState()
        val cardList = _cardList
        ConsumerCardDisplayColumn(
            cardList?.cards?.filterNotNull() ?: emptyList(),
            shouldShowScrollbar,
            cardStateFlow,
            onPullToRefresh
        ) { card ->
            onCardClicked(card)
        }
    }
}
