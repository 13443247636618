package admin.ui

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import model.MerchantLocation

@Composable
fun LocationListItemView(location: MerchantLocation, showId: Boolean = false, modifier: Modifier = Modifier) {
    ListItemView(location.name, location.id.takeIf { showId }, 48.dp, modifier)
}
