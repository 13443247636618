@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package linx.sharedui.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.DrawableResource

private object CommonMainDrawable0 {
  public val visibility: DrawableResource by 
      lazy { init_visibility() }

  public val visibility_off: DrawableResource by 
      lazy { init_visibility_off() }
}

internal val Res.drawable.visibility: DrawableResource
  get() = CommonMainDrawable0.visibility

private fun init_visibility(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:visibility",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/linx.sharedui.generated.resources/drawable/visibility.xml", -1, -1),
    )
)

internal val Res.drawable.visibility_off: DrawableResource
  get() = CommonMainDrawable0.visibility_off

private fun init_visibility_off(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:visibility_off",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/linx.sharedui.generated.resources/drawable/visibility_off.xml", -1, -1),
    )
)
