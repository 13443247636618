package admin.model.impl

import admin.model.NetworkController
import admin.persistence.TokenPersistence
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.bearerAuth
import io.ktor.client.request.get
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.contentType
import io.ktor.http.path
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import model.AdminLoginResponse
import model.CoalitionAdminUser
import model.CoalitionBody
import model.CredentialsBody
import model.DeviceHiddenBody
import model.MerchantActivity
import model.MerchantDemoBody
import model.NewRegionRequestBody
import model.PopulatedCoalition
import model.PopulatedRegion
import model.RegionChangeRequestBody
import model.TokenAdminAuthenticationResponse

class NetworkControllerKtor(
    private val httpClient: HttpClient,
    private val tokenPersistence: TokenPersistence,
) : NetworkController {

    override suspend fun submitCredentials(email: String, password: String): AdminLoginResponse {
        val response = httpClient.post {
            contentType(ContentType.Application.Json)
            url {
                path("authenticate")
            }
            setBody(Json.encodeToString(CredentialsBody(email, password)))
        }
        return when {
            response.status.value == 200 -> AdminLoginResponse.Success(response.body<TokenAdminAuthenticationResponse>())
            else -> AdminLoginResponse.Error(response.status.description)
        }
    }

    override suspend fun getPopulatedCoalitions(): List<PopulatedCoalition> {
        val token = tokenPersistence.getToken() ?: throw Exception("Missing Token")
        val response = httpClient.get {
            bearerAuth(token)
            url {
                path("populatedCoalitions")
            }
        }
        return Json.decodeFromString<List<PopulatedCoalition>>(response.bodyAsText())
    }

    override suspend fun setMerchantDemo(merchantId: Int, setAsDemo: Boolean): Boolean {
        val response = httpClient.post {
            val token = tokenPersistence.getToken() ?: throw Exception("Missing Token")
            bearerAuth(token)
            contentType(ContentType.Application.Json)
            url {
                path("setMerchantDemo")
            }
            setBody(Json.encodeToString(MerchantDemoBody(merchantId, setAsDemo)))
        }
        return when {
            response.status.value == 200 -> true
            else -> false
        }
    }

    override suspend fun submitCoalition(coalitionLongName: String, coalitionShortName: String): Boolean {
        val response = httpClient.post {
            val token = tokenPersistence.getToken() ?: throw Exception("Missing Token")
            bearerAuth(token)
            contentType(ContentType.Application.Json)
            url {
                path("submitCoalition")
            }
            setBody(Json.encodeToString(CoalitionBody(coalitionLongName, coalitionShortName)))
        }
        return when {
            response.status.value == 200 -> true
            else -> false
        }
    }

    override suspend fun setDeviceHidden(deviceId: Int, setHidden: Boolean): Boolean {
        val response = httpClient.post {
            val token = tokenPersistence.getToken() ?: throw Exception("Missing Token")
            bearerAuth(token)
            contentType(ContentType.Application.Json)
            url {
                path("setDeviceHidden")
            }
            setBody(Json.encodeToString(DeviceHiddenBody(deviceId, setHidden)))
        }
        return when {
            response.status.value == 200 -> true
            else -> false
        }
    }

    override suspend fun getMerchantActivity(): List<MerchantActivity> {
        val token = tokenPersistence.getToken() ?: throw Exception("Missing Token")
        val response = httpClient.get {
            bearerAuth(token)
            url {
                path("merchantActivity")
            }
        }
        return Json.decodeFromString<List<MerchantActivity>>(response.bodyAsText())
    }

    override suspend fun getCoalitionAdminUsers(): List<CoalitionAdminUser> {
        val token = tokenPersistence.getToken() ?: throw Exception("Missing Token")
        val response = httpClient.get {
            bearerAuth(token)
            url {
                path("coalitionAdminUsers")
            }
        }
        return Json.decodeFromString<List<CoalitionAdminUser>>(response.bodyAsText())
    }

    override suspend fun getRegions(): List<PopulatedRegion> {
        val token = tokenPersistence.getToken() ?: throw Exception("Missing Token")
        val response = httpClient.get {
            bearerAuth(token)
            url {
                path("regions")
            }
        }
        return Json.decodeFromString<List<PopulatedRegion>>(response.bodyAsText())
    }

    override suspend fun addLocation(regionId: Int, locationId: Int): Boolean {
        val token = tokenPersistence.getToken() ?: throw Exception("Missing Token")
        val response = httpClient.post {
            bearerAuth(token)
            contentType(ContentType.Application.Json)
            url {
                path("regions/location/add")
            }
            setBody(Json.encodeToString(RegionChangeRequestBody(regionId, locationId)))
        }
        return when {
            response.status.value == 200 -> true
            else -> false
        }
    }

    override suspend fun removeLocation(regionId: Int, locationId: Int): Boolean {
        val token = tokenPersistence.getToken() ?: throw Exception("Missing Token")
        val response = httpClient.post {
            bearerAuth(token)
            contentType(ContentType.Application.Json)
            url {
                path("regions/location/remove")
            }
            setBody(Json.encodeToString(RegionChangeRequestBody(regionId, locationId)))
        }
        return when {
            response.status.value == 200 -> true
            else -> false
        }
    }

    override suspend fun newRegion(regionName: String): Int? {
        val token = tokenPersistence.getToken() ?: throw Exception("Missing Token")
        val response = httpClient.post {
            bearerAuth(token)
            contentType(ContentType.Application.Json)
            url {
                path("regions/new")
            }
            setBody(Json.encodeToString(NewRegionRequestBody(regionName)))
        }
        return when {
            response.status.value == 200 -> Json.decodeFromString<Int>(response.bodyAsText())
            else -> null
        }
    }
}