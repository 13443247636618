package ui.dialogs

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import ui.AppViewModel

@Composable
fun HandleDialogs(viewModel: AppViewModel) {
    val _dialogState by viewModel.dialogStateStateFlow.collectAsState()
    val dialogState = _dialogState

    when (dialogState) {
        is AppViewModel.DialogState.Nothing -> Unit
        is AppViewModel.DialogState.LogOutConfirmation -> {
            LogOutDialog(dialogState)
        }
        is AppViewModel.DialogState.CancelEnrollment -> {
            CancelEnrollmentDialog(dialogState)
        }
        is AppViewModel.DialogState.DeleteCard -> {
            DeleteCardDialog(dialogState)
        }
    }
}