package admin.ui.merchantActivity

import admin.ui.CoalitionListItemView
import admin.ui.scrollbar.LazyColumnScrollbar
import admin.ui.scrollbar.ListIndicatorSettings
import androidx.compose.animation.core.Animatable
import androidx.compose.animation.core.LinearEasing
import androidx.compose.animation.core.RepeatMode
import androidx.compose.animation.core.infiniteRepeatable
import androidx.compose.animation.core.tween
import androidx.compose.foundation.background
import androidx.compose.foundation.horizontalScroll
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.itemsIndexed
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.foundation.rememberScrollState
import androidx.compose.material.Button
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Refresh
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.unit.dp
import formatDecimalToCurrency
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.format
import kotlinx.datetime.format.byUnicodePattern
import kotlinx.datetime.toInstant
import kotlinx.datetime.toLocalDateTime
import org.koin.compose.koinInject
import toStringOrNull

@Composable
fun MerchantActivity(
    merchantActivityViewModel: MerchantActivityViewModel = koinInject(),
    toolbarComposable: (@Composable (Modifier) -> Unit) -> Unit,
) {
    toolbarComposable { modifier ->
        if (merchantActivityViewModel.isFullAdmin()) {
            Button(merchantActivityViewModel::refreshMerchantActivity) {
                Row(verticalAlignment = Alignment.CenterVertically) {
                    Text("Refresh Transactions")

                    val isRefreshing by merchantActivityViewModel.isMerchantActivityRefreshingStateFlow.collectAsState()
                    var currentRotation by remember { mutableStateOf(0f) }
                    val rotation = remember { Animatable(currentRotation) }
                    LaunchedEffect(isRefreshing) {
                        if (isRefreshing) {
                            rotation.animateTo(
                                targetValue = currentRotation + 360f,
                                animationSpec = infiniteRepeatable(
                                    animation = tween(1000, easing = LinearEasing),
                                    repeatMode = RepeatMode.Restart
                                )
                            ) {
                                currentRotation = value
                            }
                        } else {
                            rotation.animateTo(
                                targetValue = 0f,
                                animationSpec = tween(
                                    durationMillis = 100,
                                )
                            ) {
                                currentRotation = value
                            }
                        }

                    }
                    Icon(Icons.Default.Refresh, null, modifier.rotate(rotation.value))
                }
            }
        }
    }
    val merchantActivity by merchantActivityViewModel.merchantActivityStateFlow.collectAsState()

    Row(modifier = Modifier.fillMaxWidth().horizontalScroll(rememberScrollState())) {
        Column(modifier = Modifier.width(690.dp)) {
            Row {
                Text(modifier = Modifier.width(20.dp), text = "#")
                Text(modifier = Modifier.width(78.dp), text = "Coalition")
                Text(modifier = Modifier.width(177.dp), text = "Merchant")
                Text(modifier = Modifier.width(93.dp), text = "This Hour")
                Text(modifier = Modifier.width(200.dp), text = "Last Order (local time)")
                Text(modifier = Modifier.width(90.dp), text = "Today")
            }
            val lazyListState = rememberLazyListState()
            LazyColumnScrollbar(
                listState = lazyListState,
                padding = 4.dp,
                alwaysShowScrollBar = true,
                showItemIndicator = ListIndicatorSettings.Disabled,
            ) {
                LazyColumn(
                    state = lazyListState,
                ) {
                    itemsIndexed(merchantActivity) { i, it ->
                        Row(
                            modifier = Modifier.background(merchantActivityViewModel.getColorForCoalitionId(it.coalitionId).copy(alpha = .2f)),
                            horizontalArrangement = Arrangement.spacedBy(16.dp)
                        ) {
                            Text(modifier = Modifier.width(30.dp), text = (i + 1).toString())
                            Text(modifier = Modifier.width(30.dp), text = it.coalitionId.toString())
                            Text(modifier = Modifier.width(200.dp), text = it.name)
                            Text(modifier = Modifier.width(30.dp), text = it.numberOfRecentOrders?.toStringOrNull() ?: "")
                            Text(modifier = Modifier.width(200.dp), text = it.updateDateTime.toInstant(TimeZone.UTC).toLocalDateTime(TimeZone.currentSystemDefault()).let { it.formatDate() + " " + it.formatTime() })
                            Text(modifier = Modifier.width(90.dp), text = it.dailyTotal?.formatDecimalToCurrency() ?: "")
                        }
                    }
                }
            }
        }

        val coalitions by merchantActivityViewModel.coalitionsStateFlow.collectAsState()
        Column(modifier = Modifier.width(250.dp)) {
            Text("Coalition Color Key", modifier = Modifier.padding(start = 16.dp))
            val lazyListState = rememberLazyListState()
            LazyColumnScrollbar(
                listState = lazyListState,
                padding = 4.dp,
                alwaysShowScrollBar = true,
                showItemIndicator = ListIndicatorSettings.Disabled,
            ) {
                LazyColumn(state = lazyListState) {
                    items(coalitions.populatedCoalitions) {
                        CoalitionListItemView(it, showId = true, modifier = Modifier.background(merchantActivityViewModel.getColorForCoalitionId(it.id).copy(alpha = .2f)))
                    }
                }
            }
        }
    }
}


fun LocalDateTime.formatDate(): String {
    val displayDate = this.format(LocalDateTime.Format {
        byUnicodePattern("MM/dd/yy ")
    })

    return displayDate
}

fun LocalDateTime.formatTime(): String {
    val hour = this.hour
    val (formattedHour, period) = hour.takeIf { it < 13 }?.let { it to "AM" } ?: ((hour - 12) to "PM")
    val formattedMinutes = this.minute.toString().padStart(2, '0')
    val displayTime = "$formattedHour:$formattedMinutes $period"

    return displayTime
}