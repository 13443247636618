package admin.ui

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.Dp

@Composable
fun ListItemView(name: String, id: Int?, startPadding: Dp, modifier: Modifier) {
    val text = name + (id?.let { " - $it" } ?: "")
    Text(
        modifier = modifier
            .fillMaxWidth()
            .padding(start = startPadding),
        text = text,
    )
}
