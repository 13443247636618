package admin.ui.merchantActivity

import admin.model.DataRepository
import androidx.compose.ui.graphics.Color
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import model.AdminAccess
import model.MerchantActivity
import kotlin.random.Random

class MerchantActivityViewModel(
    private val dataRepository: DataRepository,
    private val scope: CoroutineScope,
) {
    private val isMerchantActivityRefreshingMutableStateFlow = MutableStateFlow<Boolean>(false)
    val isMerchantActivityRefreshingStateFlow: StateFlow<Boolean> = isMerchantActivityRefreshingMutableStateFlow.asStateFlow()

    private val merchantActivityMutableStateFlow = MutableStateFlow<List<MerchantActivity>>(emptyList())
    val merchantActivityStateFlow = merchantActivityMutableStateFlow.asStateFlow()

    val coalitionsStateFlow = dataRepository.populatedCoalitionsStateFlow

    init {
        refreshMerchantActivity()
    }

    fun refreshMerchantActivity() {
        scope.launch {
            isMerchantActivityRefreshingMutableStateFlow.value = true
            merchantActivityMutableStateFlow.value = dataRepository.getMerchantActivity()
            isMerchantActivityRefreshingMutableStateFlow.value = false
        }
    }

    val random = Random(8675309)
    val colorHash = HashMap<Int, Color>()
    fun getColorForCoalitionId(coalitionId: Int): Color {
        val cachedColor = colorHash[coalitionId]
        if (cachedColor != null) {
            return cachedColor
        } else {
            (((colorHash.keys.maxOrNull() ?: -1) + 1)..coalitionId).forEach { i ->
                colorHash[i] = Color(
                    red = random.nextFloat(),
                    green = random.nextFloat(),
                    blue = random.nextFloat(),
                    alpha = 1f
                )
            }
            return colorHash[coalitionId]!!
        }
    }

    fun isFullAdmin(): Boolean {
        return dataRepository.accessLevel == AdminAccess.FullAdmin
    }

}