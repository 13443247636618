package admin.ui.terminalInfo

import admin.model.DataRepository
import admin.model.TrackedPopulatedCoalition
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.mapNotNull
import kotlinx.coroutines.flow.stateIn
import kotlinx.coroutines.launch
import model.AdminAccess
import model.LocationDevice
import model.PopulatedCoalition
import receipt.DeviceStatuses
import receipt.hasFlag

class TerminalInfoViewModel(
    private val dataRepository: DataRepository,
    private val scope: CoroutineScope,
) {
    sealed class DialogState {
        class None : DialogState()
        class Error : DialogState()
        class HideConfirmation(val device: LocationDevice) : DialogState()
    }

    val isPopulatedCoalitionsRefreshingStateFlow = dataRepository.isPopulatedCoalitionsRefreshingStateFlow

    private val dialogStateMutableStateFlow = MutableStateFlow<DialogState>(DialogState.None())
    val dialogStateStateFlow = dialogStateMutableStateFlow.asStateFlow()

    private val searchTextMutableStateFlow = MutableStateFlow<String?>(null)
    val searchTextStateFlow = searchTextMutableStateFlow.asStateFlow()

    val searchFilteredPopulatedCoalitionsStateFlow = dataRepository
        .populatedCoalitionsStateFlow
        .combine(searchTextMutableStateFlow) { trackedCoalitions, searchText ->
            TrackedPopulatedCoalition(filterListItems(searchText, trackedCoalitions.populatedCoalitions))
        }
        .stateIn(scope, SharingStarted.WhileSubscribed(), TrackedPopulatedCoalition())

    val activeTerminalCoalitionsStateFlow = dataRepository
        .populatedCoalitionsStateFlow
        .mapNotNull {
            TrackedPopulatedCoalition(it.populatedCoalitions.mapNotNull coalitionFilter@{ coalition ->
                val filteredMerchants = coalition.merchants.mapNotNull merchantFilter@{ merchant ->
                    val filteredLocations = merchant.locations.mapNotNull locationFilter@{ location ->
                        val filteredDevices = location.devices.filter { it.connectionId != null }
                        if (filteredDevices.isNotEmpty()) {
                            return@locationFilter location.copy(devices = filteredDevices.toMutableList())
                        }
                        return@locationFilter null
                    }.toMutableList()
                    if (filteredLocations.isNotEmpty()) {
                        return@merchantFilter merchant.copy(locations = filteredLocations)
                    }
                    return@merchantFilter null
                }.toMutableList()

                if (filteredMerchants.isNotEmpty()) {
                    return@coalitionFilter coalition.copy(merchants = filteredMerchants)
                }
                return@coalitionFilter null
            })
        }
        .stateIn(scope, SharingStarted.WhileSubscribed(), TrackedPopulatedCoalition())

    private fun filterListItems(searchText: String?, coalitions: List<PopulatedCoalition>): List<PopulatedCoalition> {
        return if (searchText != null) {
            coalitions.mapNotNull coalitionFilter@{ coalition ->
                if (coalition.name.contains(searchText, ignoreCase = true)) {
                    return@coalitionFilter coalition
                } else {
                    val filteredMerchants = coalition.merchants.mapNotNull merchantFilter@{ merchant ->
                        if (merchant.name.contains(searchText, ignoreCase = true)) {
                            return@merchantFilter merchant
                        } else {
                            val filteredLocations = merchant.locations.mapNotNull locationFilter@{ location ->
                                if (location.name.contains(searchText, ignoreCase = true)) {
                                    return@locationFilter location
                                } else {
                                    val filteredDevices = location.devices.filter { it.name.contains(searchText, ignoreCase = true) }
                                    if (filteredDevices.isNotEmpty()) {
                                        return@locationFilter location.copy(devices = filteredDevices.toMutableList())
                                    }
                                }
                                return@locationFilter null
                            }
                            if (filteredLocations.isNotEmpty()) {
                                return@merchantFilter merchant.copy(locations = filteredLocations.toMutableList())
                            }
                            return@merchantFilter null
                        }
                    }.toMutableList()

                    if (filteredMerchants.isNotEmpty()) {
                        return@coalitionFilter coalition.copy(merchants = filteredMerchants)
                    }
                    return@coalitionFilter null
                }
            }
        } else {
            coalitions
        }
    }

    fun onSearchTextChange(s: String) {
        searchTextMutableStateFlow.value = s
    }

    fun terminalClicked(device: LocationDevice) {
        if (isFullAdmin()) {
            dialogStateMutableStateFlow.value = DialogState.HideConfirmation(device)
        }
    }

    fun setTerminalHidden(device: LocationDevice) {
        scope.launch {
            val success = dataRepository.setDeviceHidden(device.id, !device.statusType.hasFlag(DeviceStatuses.Hidden))
            if (!success) {
                dialogStateMutableStateFlow.value = DialogState.Error()
            } else {
                dialogStateMutableStateFlow.value = DialogState.None()
            }
        }
    }

    fun cancelDialog() {
        dialogStateMutableStateFlow.value = DialogState.None()
    }

    fun onRefreshClicked() {
        dataRepository.requestUpdatedPopulatedCoalitions()
    }

    fun isFullAdmin(): Boolean {
        return dataRepository.accessLevel == AdminAccess.FullAdmin
    }
}