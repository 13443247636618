package ui

import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.Icon
import androidx.compose.material.IconButton
import androidx.compose.material.Text
import androidx.compose.material3.OutlinedTextField
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.input.key.Key
import androidx.compose.ui.input.key.KeyEventType
import androidx.compose.ui.input.key.key
import androidx.compose.ui.input.key.onPreviewKeyEvent
import androidx.compose.ui.input.key.type
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.PasswordVisualTransformation
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.unit.dp
import kotlinx.coroutines.flow.StateFlow
import linx.sharedui.generated.resources.Res
import linx.sharedui.generated.resources.visibility
import linx.sharedui.generated.resources.visibility_off
import org.jetbrains.compose.resources.vectorResource

@Composable
fun ColumnScope.CredentialsFields(
    emailStateFlow: StateFlow<String>,
    passwordStateFlow: StateFlow<String>,
    emailModifier: Modifier = Modifier.fillMaxWidth(),
    passwordModifier: Modifier = Modifier.fillMaxWidth(),
    passwordMask: Char = '*',
    onEmailChanged: (String) -> Unit,
    onPasswordChanged: (String) -> Unit,
    onEnterPressed: () -> Unit,
) {
    val email by emailStateFlow.collectAsState()
    Text(text = "Email:")
    OutlinedTextField(
        value = email,
        onValueChange = {
            onEmailChanged(it)
        },
        keyboardOptions = KeyboardOptions(keyboardType = KeyboardType.Email),
        shape = RoundedCornerShape(0.dp),
        singleLine = true,
        modifier = emailModifier,
    )
    val password by passwordStateFlow.collectAsState()
    var showPassword by remember { mutableStateOf(false) }
    Text(text = "Password:")
    OutlinedTextField(
        value = password,
        onValueChange = {
            onPasswordChanged(it)
        },
        keyboardOptions = KeyboardOptions(keyboardType = KeyboardType.Password),
        visualTransformation = if (showPassword) VisualTransformation.None else PasswordVisualTransformation(),
        trailingIcon = { IconButton(onClick = { showPassword = !showPassword }) {
            Icon(imageVector = vectorResource(if(showPassword) Res.drawable.visibility else Res.drawable.visibility_off), contentDescription = "Show Password")
        }},
        shape = RoundedCornerShape(0.dp),
        singleLine = true,
        modifier = passwordModifier.onPreviewKeyEvent {
            when {
                (it.key == Key.Enter && it.type == KeyEventType.KeyUp) -> {
                    onEnterPressed()
                    true
                }

                else -> {
                    false
                }
            }
        },
    )
}