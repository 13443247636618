package ui.consumerInformation

import androidx.compose.foundation.LocalIndication
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Divider
import androidx.compose.material.LinearProgressIndicator
import androidx.compose.material.Text
import androidx.compose.material3.ElevatedCard
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.semantics.clearAndSetSemantics
import androidx.compose.ui.semantics.contentDescription
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import formatDecimalToCurrency
import model.MerchantFullInformationV3
import receipt.OptInTypes
import receipt.hasFlag
import toContentDescription
import toPhoneNumberFormat
import toSingleCharacterContentDescription
import ui.Colors

@Composable
fun MerchantCard(
    store: MerchantFullInformationV3,
    onMerchantClicked: (MerchantFullInformationV3) -> Unit = {},
    shouldDeactivateClickEffects: Boolean = false,
) {
    ElevatedCard(
        modifier = Modifier
            .fillMaxWidth()
            .height(168.dp)
            .padding(horizontal = 16.dp)
            .clickable(
                interactionSource = remember { MutableInteractionSource() },
                indication = if (!shouldDeactivateClickEffects) LocalIndication.current else null,
                enabled = true,
                onClickLabel = null,
            ) {
                onMerchantClicked(store)
            }
    ) {
        Column(modifier = Modifier.fillMaxWidth()) {
            val optedIn = !store.optInType.hasFlag(OptInTypes.OptedOut)
            Column(
                modifier = Modifier
                    .background(
                        if (optedIn) {
                            Colors.Highlight
                        } else {
                            Colors.Disabled.copy(alpha = 0.2f)
                        }
                    )
                    .fillMaxWidth()
                    .padding(horizontal = 16.dp, vertical = 8.dp)
                    .weight(1f)
                    .clearAndSetSemantics {
                        contentDescription = toContentDescription(
                            store.name!!,
                            store.address!!,
                            store.phoneNumber!!.toSingleCharacterContentDescription()
                        )
                    }
            ) {
                val storeName = buildString {
                    append(store.name)
                    if (!optedIn)
                        append(" (Cancelled Enrollment)")
                }
                Text(storeName, fontWeight = FontWeight.Bold)
                Text(store.address!!)
                Text(store.phoneNumber!!.toPhoneNumberFormat())
            }
            BalanceSection(
                store,
                backgroundColor = if (optedIn) {
                    Colors.Secondary
                } else {
                    Colors.Disabled
                },
                textColor = if (optedIn) {
                    Color.White
                } else {
                    Color.Black
                }, progressBarColor = if (optedIn) {
                    Colors.Primary
                } else {
                    Colors.DisabledProgressbar
                }
            )
        }
    }
}

@Composable
fun BalanceSection(
    store: MerchantFullInformationV3,
    backgroundColor: Color = Colors.Secondary,
    textColor: Color = Color.White,
    progressBarColor: Color = Colors.Primary,
    fontWeight: FontWeight = FontWeight.Normal,
) {
    val balance = "Balance: ${store.rewardBalanceCurrent?.formatDecimalToCurrency()}"
    val isAPointStore = store.points != null && store.points?.requiredPoints != null
    val currentPointBalance = store.points?.currentPointBalance
    val requiredPoints = store.points?.requiredPoints
    Column(
        modifier = Modifier
            .clearAndSetSemantics {
                contentDescription = toContentDescription(
                    balance,
                    if (isAPointStore) {
                        "$currentPointBalance out of $requiredPoints points"
                    } else ""
                )
            }
            .background(backgroundColor)
            .fillMaxWidth()
            .heightIn(min = 56.dp)
            .padding(vertical = 8.dp)
            ,
        verticalArrangement = Arrangement.Center,
    ) {
        Text(
            balance,
            modifier = Modifier
                .align(Alignment.CenterHorizontally),
            color = textColor,
            fontWeight = fontWeight,
        )

        if (isAPointStore) {
            Divider(color = Color.Transparent, modifier = Modifier.height(8.dp))
            val progress = (currentPointBalance!! / requiredPoints!!)
            LinearProgressIndicator(
                progress = progress.toFloat(),
                strokeCap = StrokeCap.Round,
                color = progressBarColor,
                backgroundColor = Color.White,
                modifier = Modifier
                    .padding(horizontal = 16.dp)
                    .height(8.dp)
                    .fillMaxWidth()
                    .clip(RoundedCornerShape(14.dp)),
            )
            Text(
                "$currentPointBalance / $requiredPoints points",
                modifier = Modifier
                    .align(Alignment.End)
                    .padding(end = 16.dp),
                color = textColor,
                fontWeight = fontWeight,
            )
        }
    }
}