package model

import kotlinx.serialization.Serializable

@Serializable
data class ConsumerInformationV3(
    val consumer: Consumer? = Consumer(),
    val consumerContact: ConsumerContact? = ConsumerContact(),
    val cards: List<Card?> = listOf(Card()),
    val merchants: List<MerchantFullInformationV3?> = listOf(MerchantFullInformationV3()),
    val searchEnabled: Boolean,
)