// No need to match package structure, to force compile time switch of dependencies
class ProdFlavor : Flavor {
    override val clientName: String = "Linx"
    override val clientUrl: String = "linx.enrollandpay.com"
    override val serverHttps: Boolean = true
    override val serverUrl: String = "linx-appserver-prod.azurewebsites.net"
    override val serverPort: Int? = null
    override val databaseConnectionString: String =
        "jdbc:sqlserver://enrollandpay.database.windows.net:1433;database=EnrollAndPayV2;encrypt=true;trustServerCertificate=false;hostNameInCertificate=*.database.windows.net;loginTimeout=30;"
    override val serverBusConnectionString: String = "Endpoint=sb://enrollandpay.servicebus.windows.net/;SharedAccessKeyName=RootManageSharedAccessKey;SharedAccessKey=HaZRoSizhAEmgBwyHu/7AU72kI49RHtlLHI+F93jVII="
    override val enpTerminalServerHost: String = "https://enpterminalapi.azurewebsites.net/"
    override val token: String = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnZpcm9ubWVudCI6InByb2QiLCJuYW1lIjoiZW5yb2xsYW5kcGF5In0.0wo4z2SH2eKdlebPp6ew2ayJXbwafi3luIJFCI8XPbI"
}