package admin.navigation

import admin.model.DataRepository
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class Navigation(
    private val scope: CoroutineScope,
    private val dataRepository: DataRepository,
) {

    private val navigationStateMutableStateFlow: MutableStateFlow<NavigationState> = MutableStateFlow(NavigationState.Blank())
    val navigationStateFlow = navigationStateMutableStateFlow.asStateFlow()

    private val backStackMutableStateFlow = MutableStateFlow<List<NavigationState>>(emptyList())
    val backStackStateFlow = backStackMutableStateFlow.asStateFlow()

    val token = dataRepository.getToken()

    fun navigate(navigationState: NavigationState) {
        when (navigationState) {
            is NavigationState.Blank -> Unit

            is NavigationState.Login -> {
                backStackMutableStateFlow.value = emptyList()
            }

            is NavigationState.AdminHome -> {
                backStackMutableStateFlow.value = emptyList()
            }

            is NavigationState.MerchantDemoManager -> {
                backStackMutableStateFlow.value = emptyList()
            }

            is NavigationState.NameId -> {
                backStackMutableStateFlow.value = emptyList()
            }

            is NavigationState.LinxSmsIntercept -> {
                backStackMutableStateFlow.value = emptyList()
            }

            is NavigationState.TerminalInfo -> {
                backStackMutableStateFlow.value = emptyList()
            }

            is NavigationState.MerchantActivity -> {
                backStackMutableStateFlow.value = emptyList()
            }

            is NavigationState.Exit -> Unit
            is NavigationState.CoalitionCreator -> {
                backStackMutableStateFlow.value = emptyList()
            }

            is NavigationState.LocationNearbyGroupManager -> {
                backStackMutableStateFlow.value = emptyList()
            }
        }
        navigationStateMutableStateFlow.value = navigationState
    }

    fun popBackStack() {
        backStackStateFlow.value.let {
            if (it.size > 1) {
                navigate(it[it.size - 2])
            } else {
                navigate(NavigationState.Exit())
            }
        }
    }

    fun hashStateChangeEvent(newHash: String, locationListener: (String) -> Unit) {
        val navigationState = NavigationState.fromHash(newHash)
        if (navigationState == null) {
            if (backStackStateFlow.value.isEmpty()) {
                navigationStateMutableStateFlow.value = NavigationState.Login()
            }
            locationListener(navigationStateMutableStateFlow.value.asUrlString())
            return
        }

        val currentState = backStackStateFlow.value.lastOrNull()
        if (navigationState != currentState) {
            when (navigationState) {
                is NavigationState.Login -> {
                    if (dataRepository.getToken() != null) {
                        navigate(NavigationState.AdminHome())
                        return
                    } else {
                        locationListener(NavigationState.Login().asUrlString())
                    }
                }

                else -> Unit
            }
            navigate(navigationState)
        }
    }

}