package ui.dialogs

import androidx.compose.foundation.layout.widthIn
import androidx.compose.material.AlertDialog
import androidx.compose.material.Text
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import ui.AppViewModel
import ui.Colors

@Composable
fun DeleteCardDialog(
    dialogState: AppViewModel.DialogState.DeleteCard,
) {
    AlertDialog(
        modifier = Modifier.widthIn(280.dp, 320.dp),
        title =
            { Text("Delete Card") },
        text =
            { Text("Are you sure you want to delete this card?") },
        onDismissRequest =
            { dialogState.onConfirm(false) },
        confirmButton =
            {
                Button(
                    onClick = {
                        dialogState.onConfirm(true)
                        println("Deleting card yes clicked")
                              },
                    colors = ButtonDefaults.buttonColors(
                        containerColor = Colors.Secondary
                    )
                ) {
                    Text("Yes", color = Color.White)
                }
            },
        dismissButton =
            {
                Button(
                    onClick = { dialogState.onConfirm(false) },
                    colors = ButtonDefaults.buttonColors(
                        containerColor = Colors.Secondary
                    )
                ) {
                    Text("No", color = Color.White)
                }
            },
    )
}