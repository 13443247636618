package model

import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class SmsFullInformation(
    val phoneNumber: String = "",
    val smsMessageId: Long = 0L,
    val sentTime: LocalDateTime,
    val body: String = "",
)