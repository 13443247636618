package admin.ui.snackbars

import androidx.compose.material3.Snackbar
import androidx.compose.material3.SnackbarHost
import androidx.compose.material3.SnackbarHostState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.getString

@Composable
fun HandleSnackBars(snackbarStateFlow: StateFlow<SnackBarState>) {
    val _snackbarState by snackbarStateFlow.collectAsState()
    val snackbarState = _snackbarState

    if (snackbarState.isRead) return

    val snackbarHostState = remember { SnackbarHostState() }
    SnackbarHost(hostState = snackbarHostState) {
        Snackbar(
            it,
            containerColor = snackbarState.color,
        )
    }
    val scope = rememberCoroutineScope()

    when (snackbarState) {
        is SnackBarState.Nothing -> Unit
        is SnackBarState.PhoneNumberAuthRequested,
        is SnackBarState.InvalidEreceipt,
        -> {
            scope.launch {
                snackbarHostState.showSnackbar(getString(snackbarState.message))
            }
        }
    }
}