package admin.ui

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import model.CoalitionMerchant

@Composable
fun MerchantListItemView(merchant: CoalitionMerchant, showId: Boolean = false, modifier: Modifier = Modifier) {
    ListItemView(merchant.name, merchant.id.takeIf { showId }, 32.dp, modifier)
}
