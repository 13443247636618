package admin.ui.snackbars

import androidx.compose.ui.graphics.Color
import linx.adminportal.generated.resources.Res
import linx.adminportal.generated.resources.empty_string
import linx.adminportal.generated.resources.error_retrieving_ereceipt
import linx.adminportal.generated.resources.thank_you_we_will_message
import org.jetbrains.compose.resources.StringResource

sealed class SnackBarState(val message: StringResource, val color: Color = Color.Black) {
    var isRead: Boolean = false
        get() {
            val read = field
            field = true
            return read
        }
        private set

    class Nothing : SnackBarState(Res.string.empty_string)

    class PhoneNumberAuthRequested : SnackBarState(Res.string.thank_you_we_will_message)

    class InvalidEreceipt : SnackBarState(Res.string.error_retrieving_ereceipt, Color.Red)
}